var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sites-tab-container"},[_c('TableHeaderFilter',{attrs:{"headers":_vm.headers,"selectedHeaders":_vm.selectedHeaders,"menuActivator":"#org-dash-sites-col-filter"},on:{"changeHeaders":_vm.changeSelectedHeaders}}),_c('DynamicTable',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.sites,"loading":_vm.loading,"hasFooter":true,"tabSelected":"sites","menuActivator":"org-dash-sites-col-filter"},scopedSlots:_vm._u([{key:"header_n_open",fn:function(ref){
var header = ref.header;
return [_c('div',_vm._l((header.text),function(value,key){return _c('div',{key:key},[_c('div',[_vm._v(_vm._s(value))])])}),0)]}},{key:"header_turbine_model",fn:function(ref){
var header = ref.header;
return [_c('div',_vm._l((header.text),function(value,key){return _c('div',{key:key},[_c('div',[_vm._v(_vm._s(value))])])}),0)]}},{key:"header_impact_mwh",fn:function(){return [_c('div',[_c('div',[_vm._v("IMPACT")]),_c('div',{staticClass:"no-wrap-text"},[_vm._v("(MWh / $)")])])]},proxy:true},{key:"header_power_price",fn:function(){return [_c('div',{staticClass:"no-wrap-text"},[_vm._v("$/MWh")])]},proxy:true},{key:"site_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link-cell",attrs:{"to":{
          name: 'NewSiteDashboard',
          params: {
            siteId: item.site_id,
          },
        }}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"link-text"},[_vm._v(_vm._s(item.site_name))]),_c('div',{staticClass:"mr-1 pointer"},[_c('v-icon',{attrs:{"size":"1.25rem"}},[_vm._v("mdi-open-in-new")])],1)])])]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"status-item"},[_c('v-icon',{attrs:{"color":item.status}},[_vm._v("mdi-circle-medium")])],1)]}},{key:"impact_mwh",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.impact_mwh)+" MWh")]),_c('span',[_vm._v("/")]),_c('span',{staticClass:"ml-1"},[_vm._v("$")]),_vm._v(_vm._s(item.impact_usd)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }