<template>
  <div>
    <dialog
      open
      class="shared-dialog-rules"
      :class="{
        'default-dialog': !isCreateComment,
        'create-comment-dialog': isCreateComment,
        'fixed-status-dialog': isFixedStatusWidget,
      }"
    >
      <header>
        <h2>
          {{ getTitle }}
        </h2>
      </header>
      <section>
        <div class="file-container">
          <div class="file-input">
            <v-file-input
              v-model="chosenFile"
              :accept="acceptableFileExt"
              :multiple="isNotSiteDocument || isSiteDataUpload"
              :label="isFolder ? 'Select a folder' : 'Select a file'"
              :disabled="fileInputDisabled"
              :webkitdirectory="isFolder"
              show-size
              :key="fileInputKey"
              @focus="fileInputFocused($event)"
              @change="setChosenFile"
            ></v-file-input>
            <div
              class="ml-1 label"
              v-if="chosenFile.length > 0 && isSiteDataUpload"
            >
              {{ `Upload file${chosenFile.length > 1 ? "s" : ""}:` }}
            </div>
          </div>
          <div class="d-flex align-center ml-4 mr-4" v-if="isSiteDataUpload">
            <span class="mr-2 label">File</span>
            <v-switch v-model="isFolder"></v-switch>
            <span class="label">Folder</span>
          </div>
          <div
            v-if="!isNotSiteDocument && !isSiteDataUpload"
            class="select-box d-flex align-center"
          >
            <v-select
              label="Document type"
              v-model="selectedType"
              solo
              dense
              hide-details
              :items="setFileTypes"
              @click="getFileTypes"
              @change="findTypeDetails"
            >
            </v-select>
          </div>
          <div v-if="isSiteDataUpload && !isNotSiteDocument" class="select-box">
            <label class="mb-0 mr-2 label">Data type</label>
            <v-select
              v-model="selectedKind"
              @focus="errMessage = ''"
              solo
              dense
              :items="dataTypes"
            >
            </v-select>
          </div>
        </div>
        <div
          class="pb-2 px-2 multi-file-display"
          v-if="isSiteDataUpload && chosenFile.length > 0"
        >
          <div>
            <span v-for="(file, index) in chosenFile" :key="index">
              {{ fileToDisplay(file) }}
              <v-icon @click="removeFile(file)">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <form>
          <div
            class="file-dropbox"
            @drop.prevent="dragAndDropFile($event)"
            @dragstart.prevent
            @dragend.prevent
            @dragenter.prevent.stop
            @dragover.prevent.stop
            @dragleave.prevent.stop
          >
            <p class="dropbox-text">Drag and drop here</p>
            <v-icon class="upload-icon" x-large>mdi-upload</v-icon>
          </div>
          <div class="dialog-buttons">
            <div class="dialog-button">
              <v-btn outlined @click.prevent="closeDialog"> Cancel </v-btn>
            </div>
            <div class="dialog-button">
              <v-btn
                color="green"
                class="white--text"
                :disabled="uploadDisabled || loading"
                @click="uploadDocument"
                ><div>Upload</div>
                <div class="dialog-button__spinner" v-if="loading">
                  <v-progress-circular
                    :size="25"
                    color="gray"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-btn>
            </div>
          </div>
        </form>
      </section>
      <div class="dialog-alert">
        <v-alert type="error" dismissible v-if="errMessage">{{
          errMessage
        }}</v-alert>
      </div>
    </dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { findFileExt, getFile } from "@/helpers/functions";
export default {
  name: "UploadDialog",
  emits: ["postDocument, setShowDialog"],
  props: {
    showDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNotSiteDocument: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSiteDataUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    csvOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Upload",
    },
    isCreateComment: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFixedStatusWidget: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dragAndDropCapable: false,
      fileExts: ".pdf,.xls,.xlsx,.html,.ipynb,.png,.jpg,.gif,.svg,.csv",
      rawdataFileExt: ".csv,.xls,.xlsx,.parquet,.zip,.gz",
      chosenFile: [],
      selectedType: "",
      documentDefId: null,
      fileTypes: [],
      errMessage: "",
      dataTypes: [
        {
          text: "High frequency",
          value: "hf",
        },
        {
          text: "10 minute",
          value: "10m",
        },
        {
          text: "Event",
          value: "event",
        },
        {
          text: "Parameters",
          value: "parameter",
        },
        {
          text: "Met",
          value: "met",
        },
      ],
      selectedKind: "",
      isFolder: false,
      findFileExt,
      getFile,
      fileInputKey: 0,
    };
  },
  computed: {
    ...mapState({
      documentDefinitions: (state) => state.site.documentDefinitions,
      userData: (state) => state.user.userData,
    }),
    /**
     * Set the file types in the file type dropdown
     */
    setFileTypes() {
      if (this.documentDefinitions?.length > 0) {
        for (const docDef of this.documentDefinitions) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fileTypes.push(docDef.name);
        }
        return this.fileTypes;
      } else {
        return ["Loading..."];
      }
    },
    setShowDialog() {
      return this.showDialog;
    },
    getTitle() {
      if (this.isSiteDataUpload) {
        return "Upload site data";
      } else if (this.isNotSiteDocument) {
        return "Upload attachment";
      } else {
        return "Upload site document";
      }
    },
    fileInputDisabled() {
      if (
        !this.isNotSiteDocument &&
        !this.isSiteDataUpload &&
        !this.selectedType
      ) {
        return true;
      } else {
        return false;
      }
    },
    uploadDisabled() {
      if (
        ((Array.isArray(this.chosenFile) && this.chosenFile.length > 0) ||
          this.chosenFile?.name) &&
        ((!this.isSiteDataUpload &&
          this.selectedType &&
          !this.isNotSiteDocument) ||
          (this.isSiteDataUpload && this.selectedKind) ||
          this.isNotSiteDocument)
      ) {
        return false;
      } else {
        return true;
      }
    },
    acceptableFileExt() {
      if (this.isSiteDataUpload) {
        return this.rawdataFileExt;
      } else if (this.csvOnly) {
        return ".csv";
      } else {
        return this.fileExts;
      }
    },
  },
  created() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
  },
  methods: {
    ...mapActions({
      getDocumentDefinitions: "site/getDocumentDefinitions",
      postDocument: "site/postDocumentData",
    }),
    /**
     * Upload document
     */
    async uploadDocument() {
      if (!this.chosenFile) {
        this.errMessage = "Please make sure you have chosen a file to upload.";
      } else if (!this.isNotSiteDocument && !this.isSiteDataUpload) {
        // Condition for posting documents
        const siteId = this.$route.params.siteId;
        await this.postDocument({
          siteId: siteId,
          file: this.chosenFile,
          docDefId: this.documentDefId,
        });
        this.$emit("refreshDocuments");
        this.$emit("setShowDialog", false);
        this.chosenFile = [];
        this.selectedType = "";
      } else if (this.isSiteDataUpload) {
        // Condition for uploading site data
        const siteId = this.$route.params.siteId;
        let params = {
          site_id: +siteId,
          file: this.chosenFile,
          kind: this.selectedKind,
          user_id: this.userData.id,
        };
        if (this.chosenFile.length > 0) {
          this.$emit("postRawSiteData", params);
          this.$emit("setShowDialog", false);
        }

        this.chosenFile = [];
        this.selectedKind = "";
      } else {
        // Condition for attachments to comments and statuses
        this.$emit("setFile", this.chosenFile);
        this.$emit("setShowDialog", false);
        this.chosenFile = [];
      }
    },
    /**
     * Drag and drop for file upload
     */
    dragAndDropFile(event) {
      this.errMessage = "";
      event.stopPropagation();
      if (this.dragAndDropCapable) {
        if (!this.isNotSiteDocument && !this.isSiteDataUpload) {
          this.chosenFile = event.dataTransfer.files[0];
          if (event.dataTransfer.files.length > 1) {
            this.errMessage =
              "Please choose only one file to upload at a time.";
            this.chosenFile = [];
          }
        } else {
          const items = event.dataTransfer.items;
          for (var i = 0; i < items.length; i++) {
            var item = items[i].webkitGetAsEntry();
            this.scanFiles(item);
          }
        }
      }
    },
    async scanFiles(item) {
      if (item.isDirectory) {
        const directoryReader = item.createReader();
        directoryReader.readEntries((entries) => {
          entries.forEach((entry) => {
            if (entry.isDirectory) {
              this.scanFiles(entry);
            } else {
              this.checkFileExts(entry, "drop");
            }
          });
        });
      } else {
        item = await this.convertFileEntryToFile(item);
        this.checkFileExts(item, "drop");
      }
    },
    async convertFileEntryToFile(fileEntry) {
      try {
        return new Promise((resolve, reject) =>
          fileEntry.file(resolve, reject),
        );
      } catch (err) {
        this.errMessage = `Error converting fileEntry to File: ${err}`;
      }
    },
    /**
     * Make sure browser in use can process drag and drop events
     */
    determineDragAndDropCapable() {
      const div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    /**
     * Retrieve allowed file types for upload
     */
    async getFileTypes() {
      this.fileTypes = [];
      await this.getDocumentDefinitions();
    },
    /**
     * Set the file extensions allowed according to user choice in file type dropdown
     */
    findTypeDetails() {
      for (const docDef of this.documentDefinitions) {
        if (this.selectedType === docDef.name) {
          this.fileExts = docDef.allowed_extensions.join(",");
          this.documentDefId = docDef.id;
        }
      }
    },
    setChosenFile(file) {
      if (!this.chosenFile) {
        return file;
      } else if (this.isFolder) {
        for (const file of this.chosenFile) {
          this.checkFileExts(file, "input");
        }
      } else {
        return this.chosenFile;
      }
    },
    checkFileExts(item, mode) {
      const fileExt = this.findFileExt(item.name);
      if (this.isSiteDataUpload && !this.rawdataFileExt.includes(fileExt)) {
        this.errMessage = `Files that do not match the following extensions: ${this.rawdataFileExt}, will not be added.`;
        if (mode === "input") {
          this.chosenFile = this.chosenFile.filter((file) => file !== item);
        }
      } else if (mode === "drop") {
        this.chosenFile.push(item);
      }
    },
    closeDialog() {
      this.chosenFile = [];
      this.selectedType = null;
      this.errMessage = "";
      this.$emit("setShowDialog", false);
    },
    fileInputFocused() {
      this.errMessage = "";
    },
    removeFile(file) {
      this.chosenFile = this.chosenFile.filter((item) => item !== file);
    },
    fileToDisplay(file) {
      if (file.webkitRelativePath) {
        return file.webkitRelativePath;
      } else if (file.fullPath) {
        return file.fullPath.replace("/", "");
      } else {
        return file.name;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";
.shared-dialog-rules {
  border-radius: 4px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: var(--v-secondary-base);
  z-index: 1050;
}
.default-dialog {
  position: absolute;
  width: 39rem;
  top: -100px;
  left: 20%;
}
.create-comment-dialog {
  position: absolute;
  bottom: 4.25rem;
  width: 36rem;
  left: 0.5rem;
}
.fixed-status-dialog {
  position: absolute;
  top: -450px;
  left: -300px;
}
.label {
  color: var(--v-text-base) !important;
}
header {
  background-color: var(--v-primaryBlue-base);
  width: 100%;
  padding: 16px 24px 10px;
}
header h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: "Museo Sans Rounded";
  font-weight: 600;
  color: var(--v-white-base);
}
section {
  padding: 1rem;
}
.upload-icon {
  color: var(--v-text-base);
}
.file-container {
  display: flex;
  justify-content: space-between;
}
.file-input {
  width: 24rem;
  margin-top: 0.5rem;
}
.select-box {
  width: 12rem;
}
.file-dropbox {
  height: 200px;
  width: 100%;
  border: 2px dashed $primaryBlue;
  border-radius: 4px;
  text-align: center;
  padding: 10px;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.dropbox-text {
  margin-top: 2.5rem;
  font-size: 1.2rem;
  pointer-events: none;
  color: var(--v-text-base);
}
.multi-file-display {
  color: var(--v-text-base);
}
.dialog-buttons {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
.dialog-button {
  margin-left: 0.3rem;
  position: relative;
  &__spinner {
    position: absolute;
  }
}
.dialog-alert {
  max-width: 80%;
  margin: auto;
}
</style>
