var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isNewRecommendation)?_c('div',{staticClass:"blue-background"},[_c('div',{staticClass:"d-flex align-center justify-space-between issue-status"},[_c('div',{staticClass:"d-flex align-center mr-2"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-center mr-3"},[_c('label',{staticClass:"mb-0 mr-2 label"},[_vm._v("Status")]),(_vm.latestStatus.definition_id !== 6)?_c('v-tooltip',{attrs:{"right":"","disabled":_vm.latestStatus.definition_name_external !==
                'Unconfirmed (not visible)' ||
              (_vm.latestStatus.definition_name_external ===
                'Unconfirmed (not visible)' &&
                _vm.lossPct !== 'TBD')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('vue-select',{staticClass:"issue-status-select",attrs:{"options":_vm.statuses,"getOptionLabel":function (option) { return option.name_external
                        ? option.name_external
                        : option.definition_name_external; },"clearable":false,"disabled":!_vm.userHasWriteAccess ||
                    _vm.isPendingAndNotAdmin ||
                    (_vm.latestStatus.definition_name_external ===
                      'Unconfirmed (not visible)' &&
                      _vm.lossPct === 'TBD'),"components":{ OpenIndicator: _vm.OpenIndicator }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('v-icon',{attrs:{"color":_vm.setOptionStyles(
                          option.name_external
                            ? option.name_external
                            : option.definition_name_external,
                          'color'
                        )}},[_vm._v(_vm._s(_vm.setOptionStyles( option.name_external ? option.name_external : option.definition_name_external, "icon" )))]),_vm._v(" "+_vm._s(option.name_external ? option.name_external : option.definition_name_external)+" ")]}},{key:"selected-option",fn:function(option){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.setOptionStyles(
                          option.name_external
                            ? option.name_external
                            : option.definition_name_external,
                          'color'
                        )}},[_vm._v(" "+_vm._s(_vm.setOptionStyles( option.name_external ? option.name_external : option.definition_name_external, "icon" ))+" ")]),_vm._v(" "+_vm._s(option.name_external ? option.name_external : option.definition_name_external)+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]}}],null,false,3836428591)},[_c('span',[_vm._v("Can't confirm issues until losses are calculated")])]):_vm._e(),(
              (_vm.latestStatus.definition_id === 7 ||
                _vm.latestStatus.definition_id === 8 ||
                _vm.latestStatus.definition_id === 9) &&
              (_vm.selected.definition_id === 7 ||
                _vm.selected.definition_id === 8 ||
                _vm.selected.definition_id === 9)
            )?_c('div',{staticClass:"pl-1"},[(
                _vm.latestStatus.turbine_mod &&
                _vm.latestStatus.turbine_mod.definition.name
              )?_c('span',[_vm._v(" with "+_vm._s(_vm.latestStatus.turbine_mod.definition.name))]):_vm._e(),(_vm.latestStatus.ts)?_c('span',[_vm._v(" on "+_vm._s(_vm.latestStatus.ts)+" (UTC)")]):_vm._e(),_vm._v(". ")]):_vm._e(),(_vm.isSuperseded || _vm.wasSuperseded)?_c('div',[_c('span',{staticClass:"ml-4 mr-3 by-text"},[_vm._v("by")])]):_vm._e(),(_vm.isSuperseded)?_c('div',[_c('vue-select',{staticClass:"issue-select",attrs:{"options":_vm.turbineConfirmedIssues,"getOptionLabel":function (option) { return option.name; },"clearable":false,"searchable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.name))])]}},{key:"selected-option",fn:function(option){return [_c('span',{staticClass:"mt-1",class:{
                    'gray-option': option.name === 'Select issue...',
                  }},[_vm._v(" "+_vm._s(option.name)+" ")])]}}],null,false,3011263533),model:{value:(_vm.issueSelected),callback:function ($$v) {_vm.issueSelected=$$v},expression:"issueSelected"}})],1):_vm._e(),(_vm.wasSuperseded && _vm.issueData)?_c('div',[_c('router-link',{attrs:{"to":{
                name: 'IssueDetail',
                params: {
                  orgId: _vm.issueData.turbine.farm.organization_id,
                  siteId: _vm.issueData.turbine.farm_id,
                  turbineId: _vm.issueData.turbine_id,
                  issueId: _vm.supersedingIssue.superseded_by_turbine_issue_id,
                },
              }}},[_vm._v(" "+_vm._s(_vm.supersedingIssue.superseded_by_turbine_issue_name)+" ")])],1):_vm._e()],1),(
            _vm.latestStatus.definition_name_external ===
              'Actionable, awaiting recommendation' && !_vm.userHasInternalAccess
          )?_c('div',{staticClass:"d-flex ml-2"},[_vm._v(" The system is generating a recommendation and the status will be updated when it's ready. ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[(
                _vm.latestStatus.definition_name_internal ===
                'Pending verification'
              )?_c('div',{staticClass:"d-flex"},[(!_vm.isPreviousRecommendation && !_vm.isNewRecommendation)?_c('v-btn',{staticClass:"px-3 mr-1",attrs:{"disabled":_vm.isFixed,"color":"primary"},on:{"click":_vm.verifyAsFixed}},[_vm._v(" Verify as fixed ")]):_vm._e(),(!_vm.isFixed && !_vm.isNewRecommendation)?_c('v-btn',{staticClass:"px-3 mr-1",attrs:{"disabled":_vm.isPreviousRecommendation ||
                  !_vm.newestPendingImplementationStatus,"color":"primary"},on:{"click":_vm.previousRecommendation}},[_vm._v(" Re-recommend previous recommendation ")]):_vm._e(),(!_vm.isFixed && !_vm.isPreviousRecommendation)?_c('v-btn',{staticClass:"px-3 mr-1",attrs:{"disabled":_vm.isNewRecommendation,"color":"primary"},on:{"click":_vm.newRecommendation}},[_vm._v(" Add new recommendation ")]):_vm._e()],1):_vm._e()]),(_vm.showDatepicker)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('label',{staticClass:"mb-0 mr-2"},[_vm._v("as of")]),_c('div',{staticClass:"d-flex align-center"},[_c('date-picker',{staticClass:"mr-2",class:{
                    'date-error': _vm.datetimeInvalid,
                  },attrs:{"type":"date","disabled-date":_vm.disabledDates},on:{"input-error":_vm.checkDateValidity,"blur":_vm.checkDateValidity,"close":_vm.checkDateValidity},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('date-picker',{staticClass:"mr-2",class:{
                    'date-error': _vm.datetimeInvalid,
                  },attrs:{"type":"time","disabled-time":function (date) { return date <= new Date(_vm.lossesGains.issue_start_ts); }},on:{"input-error":_vm.checkDateValidity,"blur":_vm.checkDateValidity,"close":_vm.checkDateValidity},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-select',{staticClass:"timezone-select",attrs:{"items":_vm.timeZones,"item-text":"name","item-value":"value","label":"Time zone","solo":"","dense":"","hide-details":""},model:{value:(_vm.timeZone),callback:function ($$v) {_vm.timeZone=$$v},expression:"timeZone"}})],1)]),(_vm.datetimeInvalid)?_c('span',{staticClass:"error--text",staticStyle:{"font-size":"12px","position":"absolute","bottom":"5px"}},[_vm._v("Date/time must be between "+_vm._s(_vm.lossesGains.issue_start_ts)+" (UTC) and "+_vm._s(_vm.dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss") + " (UTC)"))]):_vm._e()]):_vm._e()])])]),(_vm.isPreviousRecommendation)?_c('div',[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(" "+_vm._s(_vm.newestPendingImplementationStatus.turbine_recommendation .turbine_mod_definition.description)+" ")]),_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(_vm._s(_vm.newestPendingImplementationStatus.turbine_recommendation.notes))]),(
          _vm.newestPendingImplementationStatus &&
          _vm.newestPendingImplementationStatus.attachments.length > 0
        )?_c('div',{staticClass:"d-flex align-center subtitle-2"},[_c('div',[_vm._v(" Attachments: "),_vm._l((_vm.newestPendingImplementationStatus.attachments),function(attachment,index){return _c('div',{key:index},[_c('div',{staticClass:"pointer d-flex align-center"},[_c('a',{staticClass:"ml-1",attrs:{"href":attachment.uri,"download":""}},[_vm._v(_vm._s(attachment.name))]),_c('a',{staticClass:"download-icon",attrs:{"href":attachment.uri,"download":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"25px"}},[_vm._v("mdi-file-download")])],1)])])})],2)]):_vm._e()],1):_vm._e(),(
        _vm.showLastUpdated &&
        _vm.latestStatus.definition_id !== 7 &&
        _vm.latestStatus.definition_id !== 8 &&
        _vm.latestStatus.definition_id !== 9
      )?_c('div',{staticClass:"d-flex flex-column justify-content-end"},[_c('label',{staticClass:"mb-0 last-update-date"},[_vm._v(_vm._s(_vm.dateLastUpdated))])]):_vm._e()]),(
      (_vm.latestStatus.definition_id === 7 ||
        _vm.latestStatus.definition_id === 8 ||
        _vm.latestStatus.definition_id === 9) &&
      (_vm.selected.definition_id === 7 ||
        _vm.selected.definition_id === 8 ||
        _vm.selected.definition_id === 9)
    )?_c('div',{staticClass:"p-3 d-flex justify-end"},[(_vm.latestStatus.user_full_name && _vm.latestStatus.created_ts)?_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Last updated "+_vm._s(_vm.latestStatus.created_ts)+" (UTC) by "+_vm._s(_vm.latestStatus.user_full_name)+".")]):_vm._e()]):_vm._e(),(
      _vm.selected.name_external === 'Fixed' ||
      (_vm.selectedTurbineModDef &&
        _vm.selectedTurbineModDef.id &&
        (_vm.selectedTurbineModDef.id === 16 || _vm.selectedTurbineModDef.id === 17))
    )?_c('div',{staticClass:"d-flex align-center pl-4 pb-4"},[_vm._v(" By "),_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.selected.name_external === 'Fixed')?_c('v-autocomplete',_vm._g(_vm._b({staticClass:"mod-select pl-1",attrs:{"placeholder":"Available mods","items":_vm.turbineModDefs,"item-text":"name","return-object":"","solo":"","dense":"","hide-details":""},model:{value:(_vm.selectedTurbineModDef),callback:function ($$v) {_vm.selectedTurbineModDef=$$v},expression:"selectedTurbineModDef"}},'v-autocomplete',attrs,false),on)):_vm._e()]}}],null,false,1010760520)},[(
          !_vm.selectedTurbineModDef ||
          (_vm.selectedTurbineModDef &&
            Object.keys(_vm.selectedTurbineModDef).length === 0)
        )?_c('span',[_vm._v("Please select the modification that fixed this issue")]):(_vm.selectedTurbineModDef)?_c('span',[_vm._v(_vm._s(_vm.selectedTurbineModDef.description))]):_vm._e()]),(
        _vm.selectedTurbineModDef && _vm.selectedTurbineModDef.requires_value === true
      )?_c('v-text-field',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"label":_vm.valueLabel,"solo":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.modValue),callback:function ($$v) {_vm.modValue=$$v},expression:"modValue"}}):_vm._e(),(
        _vm.selectedTurbineModDef &&
        _vm.selectedTurbineModDef.id &&
        (_vm.selectedTurbineModDef.id === 16 || _vm.selectedTurbineModDef.id === 17)
      )?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"value-input pl-2",attrs:{"solo":"","dense":"","hide-details":"","clearable":"","label":"Mod notes"},model:{value:(_vm.modNotes),callback:function ($$v) {_vm.modNotes=$$v},expression:"modNotes"}},'v-text-field',attrs,false),on))]}}],null,false,2145086200)},[_c('span',[_vm._v("Please describe the modification that fixed this issue")])]):_vm._e()],1):_vm._e(),(
      _vm.latestStatus.definition_name_external === 'Fixed' &&
      _vm.latestStatus.attachments.length > 0
    )?_c('div',{staticClass:"d-flex align-center subtitle-2 pa-4"},[_vm._v(" Attachments: "),_vm._l((_vm.latestStatus.attachments),function(attachment,index){return _c('div',{key:index},[_c('div',{staticClass:"pointer d-flex align-center"},[_c('a',{staticClass:"ml-1",attrs:{"href":attachment.uri,"download":""}},[_vm._v(_vm._s(attachment.name))]),_c('a',{staticClass:"download-icon",attrs:{"href":attachment.uri,"download":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"25px"}},[_vm._v("mdi-file-download")])],1)])])})],2):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[(_vm.showComment || _vm.isFixed || _vm.isPreviousRecommendation)?_c('div',{staticClass:"d-flex align-center justify-space-between pa-4 w100"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.showComment)?_c('v-textarea',_vm._g(_vm._b({staticStyle:{"max-width":"50%"},attrs:{"solo":"","dense":"","clearable":"","rows":"1","label":"Comment","hide-details":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}},'v-textarea',attrs,false),on)):_vm._e()]}}],null,false,820962400)},[_c('span',[_vm._v("Any additional comments about fixing the issue that would be useful to save")])]),(
          _vm.isFixed ||
          _vm.isPreviousRecommendation ||
          _vm.selected.name_external === 'Fixed'
        )?_c('div',[_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleUploadDialog(true)}}},[_c('img',{staticClass:"upload-img mr-1",attrs:{"src":"/img/file-upload.svg"}}),_vm._v(" Upload attachments ")]),(_vm.attachments)?_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.attachments),function(attachment,index){return _c('span',{key:index,staticClass:"pl-2"},[_vm._v(" "+_vm._s(attachment.name)+" "),_c('v-icon',{on:{"click":function($event){return _vm.removeAttachment(attachment)}}},[_vm._v("mdi-close")])],1)}),0):_vm._e()],1)]):_vm._e(),(_vm.showDialog)?_c('UploadDialog',{attrs:{"isNotSiteDocument":true,"title":"Upload attachment"},on:{"setShowDialog":_vm.toggleUploadDialog,"setFile":_vm.setFile}}):_vm._e(),(_vm.showDialog)?_c('div',{staticClass:"backdrop",on:{"click":function($event){return _vm.toggleUploadDialog(false)}}}):_vm._e()],1):_vm._e(),(_vm.showSave)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center mr-4"},[_c('v-btn',{staticClass:"px-3 mr-1",attrs:{"outlined":""},on:{"click":_vm.cancelStatusChange}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"px-4 white--text",attrs:{"color":"green","loading":_vm.saveStatusLoading,"disabled":_vm.shouldDisableSaveButton},on:{"click":function($event){return _vm.saveIssueStatus()}}},[_vm._v(" Save")])],1)]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }