<template>
  <div class="popup-container">
    <div class="popup-slot" @mouseover="showPopUp" @mouseout="hidePopUp">
      <slot name="hover" />
    </div>
    <div class="popup" ref="PopUp" @mouseover="showPopUp" @mouseout="hidePopUp">
      <slot name="content" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showPopUp() {
      const element = this.$refs.PopUp;
      if (!element) return;
      if (element.classList.contains("is-visible")) return;
      if ((this.content === null || this.content.length === 0) && !this.loading)
        return;
      element.classList.add("is-visible");
    },
    hidePopUp() {
      const element = this.$refs.PopUp;
      if (!element) return;
      if (!element.classList.contains("is-visible")) return;
      element.classList.remove("is-visible");
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/_variables";
.popup-container {
  position: relative;
  .popup {
    width: 500px;
    max-height: 225px;
    position: absolute;
    z-index: 1001;
    border: 1px solid #d8dbe0;
    border-radius: 0.25rem;
    color: var(--v-text-base);
    background-color: #fff;
    visibility: hidden;

    opacity: 0;

    &.is-visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s ease-in-out 0.5s;
    }
    .popup-slot {
      display: inline-block;
      padding: 12px 16px;
    }
    // .popup-link {
    //   cursor: pointer;
    // }
    .popup-content {
      background-color: #fff;
      max-height: 225px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 12px 16px;
    }

    p:last-child {
      margin-bottom: 0px;
    }
  }

  .status {
    border-radius: 1rem;
    padding: 2px 8px;
    white-space: nowrap;
    width: fit-content;
  }
  .dropdown-item {
    padding: 0;
    &:hover {
      background-color: var(--v-hover-base);
    }
  }
}
.theme--dark .popup {
  background-color: var(--v-background-base);
  border: 1px solid hsla(0, 0%, 100%, 0.2) !important;
}
table .popup-container .popup {
  bottom: -50px;
  left: 20px;
}
</style>
