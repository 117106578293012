<template>
  <div class="h100 health-card-container">
    <div
      class="d-flex justify-center align-center health-card-container__spinner"
      v-if="loading"
    >
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="d-flex flex-column justify-space-between h100">
      <div class="header-container orientation-header">
        <p class="mb-0">{{ name }}</p>
      </div>
      <!-- Chart container -->
      <div id="health-chart-container" class="flex-item-chart">
        <div class="health-chart"></div>
      </div>
      <!-- Chart metadata -->
      <div class="chart-metadata flex-item">
        <div class="chart-metadata__content">
          <p class="mb-0" v-if="criticalIssues > 0">
            Actionable improvement opportunity.
          </p>
          <p class="mb-2">Assessed based on {{ issueType }}.</p>
        </div>
      </div>
      <!-- Knowledge base link -->
      <div class="knowledge-link">
        <!-- Should link to specific article in knowledge base -->
        <a
          href="https://www.windesco.com/knowledge/what-types-of-analysis-does-windesco-provide"
          target="_blank"
          >Learn more</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";
import { COLORS } from "@/helpers/colors";
export default {
  name: "HealthWidget",
  props: {
    name: {
      type: String,
      required: false,
      default: "Health",
    },
    nCriticalIssues: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    criticalIssues() {
      return this.nCriticalIssues;
    },
    issueType() {
      if (this.name.toLowerCase().includes("performance")) {
        return "open performance issues";
      } else if (this.name.toLowerCase().includes("health")) {
        return "open health issues";
      } else {
        return "open issues";
      }
    },
  },
  methods: {
    createChart() {
      const nOpenIssues = this.nCriticalIssues;
      const viewBoxHeight = 100; // Fixed height
      const container = d3.select(this.$el).select(".health-chart");
      const containerWidth = container.node().getBoundingClientRect().width; // Dynamic width

      // Remove the old SVG to ensure resizing works correctly when this function is called again
      container.select("svg").remove();

      const svg = container
        .append("svg")
        .attr("viewBox", `0 0 ${containerWidth} ${viewBoxHeight}`)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .classed("svg-content-responsive", true);

      const xScale = d3
        .scaleLinear()
        .domain([0, 5])
        .range([containerWidth - 10, 10]);

      let color, text;
      if (nOpenIssues > 5) {
        color = COLORS.red;
        text = "Needs attention";
      } else if (nOpenIssues === -1) {
        color = COLORS.gray;
        text = "Unavailable";
      } else if (nOpenIssues === 0) {
        color = COLORS.green;
        text = "Healthy";
      } else {
        color = COLORS.yellow;
        text = "Suboptimal";
      }

      const lineColor = "var(--v-text-base)";
      svg
        .append("line")
        .attr("x1", 0)
        .attr("y1", viewBoxHeight / 2)
        .attr("x2", containerWidth)
        .attr("y2", viewBoxHeight / 2)
        .attr("stroke", lineColor)
        .attr("stroke-width", 3);

      svg
        .append("circle")
        .attr("cx", nOpenIssues > 5 ? 10 : xScale(nOpenIssues))
        .attr("cy", viewBoxHeight / 2)
        .attr("r", 8)
        .attr("fill", color);

      svg
        .append("text")
        .attr("y", viewBoxHeight - 10)
        .classed("my-2", true)
        .style("font", '1.25rem "Museo Sans Rounded", sans-serif')
        .style("font-weight", "bold")
        .style("fill", "var(--v-text-base)")
        .text(text);
    },
    resizeChart() {
      this.createChart();
    },
  },
  watch: {
    nCriticalIssues: {
      immediate: true,
      handler() {
        // d3.select(this.$el).select(".health-chart svg").remove();
        this.$nextTick(() => {
          this.createChart();
        });
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeChart);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeChart);
  },
};
</script>
<style lang="scss" scoped>
.health-card-container {
  position: relative;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.flex-item-chart {
  flex: 1;
}

.svg-content-responsive {
  width: 100%;
  max-height: 100px;
}

.header-container {
  flex: 0.25;
}
.header {
  font:
    0.75rem "Inter",
    sans-serif;
  color: var(--v-text-base);
}
.text-color {
  color: var(--v-text-base);
}

.chart-metadata {
  flex: 1;
  margin-top: 1.5rem;

  &__content {
    font:
      0.875rem "Inter",
      sans-serif;
  }
}
</style>
