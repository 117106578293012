<template>
  <div v-if="!isNewRecommendation" class="blue-background">
    <div class="d-flex align-center justify-space-between issue-status">
      <div class="d-flex align-center mr-2">
        <div class="d-flex">
          <div class="d-flex align-center mr-3">
            <label class="mb-0 mr-2 label">Status</label>
            <v-tooltip
              v-if="latestStatus.definition_id !== 6"
              right
              :disabled="
                latestStatus.definition_name_external !==
                  'Unconfirmed (not visible)' ||
                (latestStatus.definition_name_external ===
                  'Unconfirmed (not visible)' &&
                  lossPct !== 'TBD')
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <vue-select
                    :options="statuses"
                    :getOptionLabel="
                      (option) =>
                        option.name_external
                          ? option.name_external
                          : option.definition_name_external
                    "
                    :clearable="false"
                    :disabled="
                      !userHasWriteAccess ||
                      isPendingAndNotAdmin ||
                      (latestStatus.definition_name_external ===
                        'Unconfirmed (not visible)' &&
                        lossPct === 'TBD')
                    "
                    :components="{ OpenIndicator }"
                    class="issue-status-select"
                    v-model="selected"
                  >
                    <template slot="option" slot-scope="option">
                      <v-icon
                        :color="
                          setOptionStyles(
                            option.name_external
                              ? option.name_external
                              : option.definition_name_external,
                            'color',
                          )
                        "
                        >{{
                          setOptionStyles(
                            option.name_external
                              ? option.name_external
                              : option.definition_name_external,
                            "icon",
                          )
                        }}</v-icon
                      >
                      {{
                        option.name_external
                          ? option.name_external
                          : option.definition_name_external
                      }}
                    </template>
                    <template v-slot:selected-option="option">
                      <v-icon
                        :color="
                          setOptionStyles(
                            option.name_external
                              ? option.name_external
                              : option.definition_name_external,
                            'color',
                          )
                        "
                        class="mr-2"
                      >
                        {{
                          setOptionStyles(
                            option.name_external
                              ? option.name_external
                              : option.definition_name_external,
                            "icon",
                          )
                        }}
                      </v-icon>
                      {{
                        option.name_external
                          ? option.name_external
                          : option.definition_name_external
                      }}
                    </template>
                  </vue-select>
                </div>
              </template>
              <span>Can't confirm issues until losses are calculated</span>
            </v-tooltip>
            <div
              v-if="
                (latestStatus.definition_id === 7 ||
                  latestStatus.definition_id === 8 ||
                  latestStatus.definition_id === 9) &&
                (selected.definition_id === 7 ||
                  selected.definition_id === 8 ||
                  selected.definition_id === 9)
              "
              class="pl-1"
            >
              <span
                v-if="
                  latestStatus.turbine_mod &&
                  latestStatus.turbine_mod.definition.name
                "
              >
                with
                {{ latestStatus.turbine_mod.definition.name }}</span
              >
              <span v-if="latestStatus.ts"> on {{ latestStatus.ts }} (UTC)</span
              >.
            </div>
            <div v-if="isSuperseded || wasSuperseded">
              <span class="ml-4 mr-3 by-text">by</span>
            </div>
            <div v-if="isSuperseded">
              <vue-select
                :options="turbineConfirmedIssues"
                :getOptionLabel="(option) => option.name"
                :clearable="false"
                :searchable="false"
                v-model="issueSelected"
                class="issue-select"
              >
                <template slot="option" slot-scope="option">
                  <span>{{ option.name }}</span>
                </template>
                <template v-slot:selected-option="option">
                  <span
                    class="mt-1"
                    :class="{
                      'gray-option': option.name === 'Select issue...',
                    }"
                  >
                    {{ option.name }}
                  </span>
                </template>
              </vue-select>
            </div>
            <div v-if="wasSuperseded && issueData">
              <router-link
                :to="{
                  name: 'IssueDetail',
                  params: {
                    orgId: issueData.turbine.farm.organization_id,
                    siteId: issueData.turbine.farm_id,
                    turbineId: issueData.turbine_id,
                    issueId: supersedingIssue.superseded_by_turbine_issue_id,
                  },
                }"
              >
                {{ supersedingIssue.superseded_by_turbine_issue_name }}
              </router-link>
            </div>
          </div>
          <div
            class="d-flex ml-2"
            v-if="
              latestStatus.definition_name_external ===
                'Actionable, awaiting recommendation' && !userHasInternalAccess
            "
          >
            The system is generating a recommendation and the status will be
            updated when it's ready.
          </div>
          <div class="d-flex">
            <div class="d-flex">
              <div
                class="d-flex"
                v-if="
                  latestStatus.definition_name_internal ===
                  'Pending verification'
                "
              >
                <v-btn
                  v-if="!isPreviousRecommendation && !isNewRecommendation"
                  :disabled="isFixed"
                  color="primary"
                  class="px-3 mr-1"
                  @click="verifyAsFixed"
                >
                  Verify as fixed
                </v-btn>
                <v-btn
                  v-if="!isFixed && !isNewRecommendation"
                  :disabled="
                    isPreviousRecommendation ||
                    !newestPendingImplementationStatus
                  "
                  color="primary"
                  class="px-3 mr-1"
                  @click="previousRecommendation"
                >
                  Re-recommend previous recommendation
                </v-btn>
                <v-btn
                  v-if="!isFixed && !isPreviousRecommendation"
                  :disabled="isNewRecommendation"
                  color="primary"
                  class="px-3 mr-1"
                  @click="newRecommendation"
                >
                  Add new recommendation
                </v-btn>
              </div>
            </div>
            <div class="d-flex flex-column align-center" v-if="showDatepicker">
              <div class="d-flex align-center">
                <label class="mb-0 mr-2">as of</label>
                <div class="d-flex align-center">
                  <date-picker
                    type="date"
                    v-model="date"
                    class="mr-2"
                    :class="{
                      'date-error': datetimeInvalid,
                    }"
                    :disabled-date="disabledDates"
                    @input-error="checkDateValidity"
                    @blur="checkDateValidity"
                    @close="checkDateValidity"
                  />
                  <date-picker
                    type="time"
                    v-model="date"
                    class="mr-2"
                    :class="{
                      'date-error': datetimeInvalid,
                    }"
                    :disabled-time="
                      (date) => date <= new Date(lossesGains.issue_start_ts)
                    "
                    @input-error="checkDateValidity"
                    @blur="checkDateValidity"
                    @close="checkDateValidity"
                  />
                  <v-select
                    v-model="timeZone"
                    :items="timeZones"
                    class="timezone-select"
                    item-text="name"
                    item-value="value"
                    label="Time zone"
                    solo
                    dense
                    hide-details
                  />
                </div>
              </div>
              <span
                v-if="datetimeInvalid"
                class="error--text"
                style="font-size: 12px; position: absolute; bottom: 5px"
                >Date/time must be between
                {{ lossesGains.issue_start_ts }} (UTC) and
                {{
                  dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss") + " (UTC)"
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="isPreviousRecommendation">
        <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">
          {{
            newestPendingImplementationStatus.turbine_recommendation
              .turbine_mod_definition.description
          }}
        </vue-markdown>
        <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">{{
          newestPendingImplementationStatus.turbine_recommendation.notes
        }}</vue-markdown>
        <div
          v-if="
            newestPendingImplementationStatus &&
            newestPendingImplementationStatus.attachments.length > 0
          "
          class="d-flex align-center subtitle-2"
        >
          <div>
            Attachments:
            <div
              v-for="(
                attachment, index
              ) in newestPendingImplementationStatus.attachments"
              :key="index"
            >
              <div class="pointer d-flex align-center">
                <a class="ml-1" :href="attachment.uri" download>{{
                  attachment.name
                }}</a>
                <a :href="attachment.uri" download class="download-icon">
                  <v-icon class="mr-1" size="25px">mdi-file-download</v-icon></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column justify-content-end"
        v-if="
          showLastUpdated &&
          latestStatus.definition_id !== 7 &&
          latestStatus.definition_id !== 8 &&
          latestStatus.definition_id !== 9
        "
      >
        <label class="mb-0 last-update-date">{{ dateLastUpdated }}</label>
      </div>
    </div>
    <div
      v-if="
        (latestStatus.definition_id === 7 ||
          latestStatus.definition_id === 8 ||
          latestStatus.definition_id === 9) &&
        (selected.definition_id === 7 ||
          selected.definition_id === 8 ||
          selected.definition_id === 9)
      "
      class="p-3 d-flex justify-end"
    >
      <span
        v-if="latestStatus.user_full_name && latestStatus.created_ts"
        style="font-size: 12px"
      >
        Last updated
        {{ latestStatus.created_ts }} (UTC) by
        {{ latestStatus.user_full_name }}.</span
      >
    </div>
    <div
      v-if="
        selected.name_external === 'Fixed' ||
        (selectedTurbineModDef &&
          selectedTurbineModDef.id &&
          (selectedTurbineModDef.id === 16 || selectedTurbineModDef.id === 17))
      "
      class="d-flex align-center pl-4 pb-4"
    >
      By
      <v-tooltip top open-delay="200">
        <template v-slot:activator="{ on, attrs }">
          <v-autocomplete
            v-if="selected.name_external === 'Fixed'"
            v-model="selectedTurbineModDef"
            v-bind="attrs"
            v-on="on"
            placeholder="Available mods"
            :items="turbineModDefs"
            class="mod-select pl-1"
            item-text="name"
            return-object
            solo
            dense
            hide-details
          />
        </template>
        <span
          v-if="
            !selectedTurbineModDef ||
            (selectedTurbineModDef &&
              Object.keys(selectedTurbineModDef).length === 0)
          "
          >Please select the modification that fixed this issue</span
        >
        <span v-else-if="selectedTurbineModDef">{{
          selectedTurbineModDef.description
        }}</span>
      </v-tooltip>

      <v-text-field
        v-if="
          selectedTurbineModDef && selectedTurbineModDef.requires_value === true
        "
        v-model="modValue"
        class="ml-2"
        :label="valueLabel"
        style="max-width: 150px"
        solo
        dense
        hide-details
        clearable
      >
      </v-text-field>

      <!-- If selected mod is 'other' then require notes, id 16 or 17 -->
      <v-tooltip
        bottom
        open-delay="200"
        v-if="
          selectedTurbineModDef &&
          selectedTurbineModDef.id &&
          (selectedTurbineModDef.id === 16 || selectedTurbineModDef.id === 17)
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            v-on="on"
            v-model="modNotes"
            solo
            dense
            hide-details
            clearable
            label="Mod notes"
            class="value-input pl-2"
          ></v-text-field>
        </template>
        <span>Please describe the modification that fixed this issue</span>
      </v-tooltip>
    </div>
    <div
      v-if="
        latestStatus.definition_name_external === 'Fixed' &&
        latestStatus.attachments.length > 0
      "
      class="d-flex align-center subtitle-2 pa-4"
    >
      Attachments:
      <div v-for="(attachment, index) in latestStatus.attachments" :key="index">
        <div class="pointer d-flex align-center">
          <a class="ml-1" :href="attachment.uri" download>{{
            attachment.name
          }}</a>
          <a :href="attachment.uri" download class="download-icon">
            <v-icon class="mr-1" size="25px">mdi-file-download</v-icon></a
          >
        </div>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <div
        v-if="showComment || isFixed || isPreviousRecommendation"
        class="d-flex align-center justify-space-between pa-4 w100"
      >
        <v-tooltip bottom open-delay="200">
          <template v-slot:activator="{ on, attrs }">
            <v-textarea
              v-if="showComment"
              v-bind="attrs"
              v-on="on"
              v-model="comment"
              solo
              dense
              clearable
              rows="1"
              label="Comment"
              hide-details
              style="max-width: 50%"
            ></v-textarea>
          </template>
          <span
            >Any additional comments about fixing the issue that would be useful
            to save</span
          >
        </v-tooltip>
        <div
          v-if="
            isFixed ||
            isPreviousRecommendation ||
            selected.name_external === 'Fixed'
          "
        >
          <div>
            <v-btn color="primary" @click="toggleUploadDialog(true)">
              <img src="/img/file-upload.svg" class="upload-img mr-1" />
              Upload attachments
            </v-btn>
            <div v-if="attachments" class="d-flex align-center">
              <span
                v-for="(attachment, index) in attachments"
                :key="index"
                class="pl-2"
              >
                {{ attachment.name }}
                <v-icon @click="removeAttachment(attachment)">mdi-close</v-icon>
              </span>
            </div>
          </div>
        </div>
        <UploadDialog
          v-if="showDialog"
          :isNotSiteDocument="true"
          title="Upload attachment"
          @setShowDialog="toggleUploadDialog"
          @setFile="setFile"
        />
        <div
          @click="toggleUploadDialog(false)"
          class="backdrop"
          v-if="showDialog"
        ></div>
      </div>
      <div class="d-flex align-center" v-if="showSave">
        <div class="d-flex align-center mr-4">
          <v-btn class="px-3 mr-1" outlined @click="cancelStatusChange"
            >Cancel</v-btn
          >
          <v-btn
            color="green"
            class="px-4 white--text"
            :loading="saveStatusLoading"
            :disabled="shouldDisableSaveButton"
            @click="saveIssueStatus()"
          >
            Save</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import vueSelect from "vue-select";
import { issueStatusOptions, clickCountLimit } from "@/helpers/variables";
import VueMarkdown from "vue-markdown-v2";
import UploadDialog from "@/components/UploadDialog";
import OpenIndicator from "@/components/OpenIndicator.vue";
import { DateTime } from "luxon";
import timeZones from "@/helpers/timezones";
import "vue2-datepicker/index.css";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";

/**
 * Display widget for issue statuses dropdown
 */

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
// vueSelect.props.components.default = () => ({ OpenIndicator });

export default {
  name: "IssueStatusWidget",
  components: {
    DatePicker,
    vueSelect,
    VueMarkdown,
    UploadDialog,
  },
  emits: ["updateStatus"],
  props: {
    /**
     * Determines whether or not a user has write access
     *
     * This will enable the user to change the issue status if true or disable if false
     */
    userHasWriteAccess: {
      type: Boolean,
      default: false,
      required: true,
    },
    userHasInternalAccess: {
      type: Boolean,
      default: false,
      required: true,
    },
    lossPct: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      /**
       * Holds the value of the date picker for status selections that require a timestamp
       */
      date: null,
      /**
       * List of possible icons for each issue status
       */
      statusStyleOptions: issueStatusOptions,
      /**
       * Determines whether or not the datepicker is visible
       */
      showDatepicker: false,
      /**
       * Determines if the save button should be shown next to a status
       */
      showSave: false,
      /**
       * Determines if the latest issue status date should be shown or not
       */
      showLastUpdated: true,
      /**
       * Holds the value for the latest issue status date
       */
      dateLastUpdated: null,
      /**
       * Holds the value of an issue status icon color
       */
      iconColor: "",
      /**
       * Holds the value of an issue status icon
       */
      iconStyle: "",
      /**
       * Sets the initial value of the issue status dropdown to the latest status
       */
      selected: {},
      issueSelected: {
        name: "Select issue...",
      },
      /**
       * Determines the loading state when selecting to assign a different status to an issue
       */
      saveStatusLoading: false,
      /**
       * Holds the attributes to be added to the `vue-markdown` link
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      recommendationSelection: null,
      isFixed: false,
      isPreviousRecommendation: false,
      isNewRecommendation: false,
      isSuperseded: false,
      showDialog: false,
      attachments: [],
      timeZones,
      timeZone: null,
      showComment: false,
      comment: "",
      datetimeInvalid: false,
      dateErrMsg: "",
      turbineIssuesLoading: false,
      selectedTurbineModDef: {},
      modNotes: "",
      modValue: "",
      OpenIndicator,
      dayjs,
      showViewer: false,
      viewerLoading: false,
      documentObj: {},
      pdfEvent: {},
      pdfPresent: false,
      pdfTotalPages: 0,
      pdfCurrentPage: 1,
    };
  },
  computed: {
    ...mapState({
      updateIssueStatusResponse: (state) =>
        state.issueDetail.updateIssueStatusResponse,
      statusDataloading: (state) =>
        state.issueDetail.loading.getIssueStatusData,
      issueStatuses: (state) => state.issueDetail.issueStatuses,
      statusHistory: (state) => state.issueDetail.statusHistory,
      latestStatus: (state) => state.issueDetail.latestStatus,
      cancel: (state) => state.issueDetail.cancel,
      lossesGains: (state) => state.issueDetail.lossesGains,
      issueData: (state) => state.issueDetail.issueData,
      confirmedIssues: (state) => state.issues.confirmedIssues,
      turbineIssues: (state) => state.issues.turbineIssues,
      turbineModDefs: (state) => state.issueDetail.turbineModDefs,
      suggestedRecommendation: (state) =>
        state.issueDetail.suggestedRecommendation,
      clickCount: (state) => state.app.clickCount,
    }),
    shouldDisableSaveButton() {
      const isFixedAndNoModSelected =
        this.selected?.name_external === "Fixed" &&
        this.selectedTurbineModDef &&
        Object.keys(this.selectedTurbineModDef).length === 0;
      const isOtherModAndNoNotes =
        (this.selectedTurbineModDef?.id === 16 ||
          this.selectedTurbineModDef?.id === 17) &&
        this.modNotes === "";
      const isModRequiresValueAndNoValue =
        this.selectedTurbineModDef?.requires_value === 1 &&
        (this.modValue === "" || this.modValue === null);

      return (
        this.saveStatusLoading ||
        this.datetimeInvalid ||
        (this.isSuperseded && this.issueSelected.name === "Other issue") ||
        this.dateRequiredAndIncomplete ||
        isFixedAndNoModSelected ||
        (this.selectedTurbineModDef &&
          (isOtherModAndNoNotes || isModRequiresValueAndNoValue))
      );
    },
    valueLabel() {
      return this.selectedTurbineModDef?.value_units
        ? `Value (${this.selectedTurbineModDef?.value_units})`
        : "Value";
    },
    dateRequiredAndIncomplete() {
      return (
        this.selected.require_timestamp_input &&
        (this.date === null || this.timeZone === null)
      );
    },
    statuses() {
      if (this.issueStatuses?.length > 0) {
        return this.issueStatuses;
      } else {
        return ["Loading..."];
      }
    },
    turbineConfirmedIssues() {
      const issueId = +this.$route.params.issueId;

      if (
        issueId &&
        this.confirmedIssues?.length > 0 &&
        this.issueData &&
        !this.latestStatus.superseded_by_turbine_issue_id
      ) {
        // Make sure that this issue can not supersede itself and
        // can not be superseded by an issue that it has superseded
        const supersededIssuesArr = [];
        const confirmedArr = [];

        for (const item of this.issueData.superseded_turbine_issues) {
          supersededIssuesArr.push(item.id);
        }
        for (const issue of this.confirmedIssues) {
          if (issueId !== issue.id && !supersededIssuesArr.includes(issue.id)) {
            confirmedArr.push(issue);
          }
        }
        return confirmedArr;
      } else {
        return [];
      }
    },
    /**
     * Returns latest status value for a watcher
     *
     * The watcher updates the latest status and date when the latest status value changes
     */
    watchLatestStatus() {
      return this.latestStatus;
    },
    /**
     * Disables the status select dropdown if the user's role does not have admin or write access
     */
    selectDisabled() {
      return (
        this.role &&
        (this.role.includes("write") || this.role.includes("admin"))
      );
    },
    isPendingAndNotAdmin() {
      return (
        this.latestStatus.definition_name_internal === "Pending verification" &&
        !this.userHasInternalAccess
      );
    },
    /**
     * Returns the last status with definition name "Pending implementation"
     */
    newestPendingImplementationStatus() {
      return this.statusHistory
        .slice()
        .reverse()
        .find(
          (status) =>
            status.definition_name_internal === "Pending implementation",
        );
    },
    wasSuperseded() {
      if (this.latestStatus?.superseded_by_turbine_issue_id) {
        return true;
      } else {
        return false;
      }
    },
    supersedingIssue() {
      if (this.wasSuperseded) {
        return this.latestStatus;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions({
      updateIssueStatus: "issueDetail/updateIssueStatus",
      updateRecommendationSelection:
        "issueDetail/updateRecommendationSelection",
      clickedCancel: "issueDetail/clickedCancel",
      getIssueStatusData: "issueDetail/getIssueStatusData",
      getTurbineModDefs: "issueDetail/getTurbineModDefs",
      incrementClickCount: "app/incrementClickCount",
    }),
    /**
     * Sets the icon styles for the status icons in the dropdown
     */
    setOptionStyles(status, property) {
      let styleOption = {
        color: "",
        icon: "",
      };

      styleOption = this.statusStyleOptions.find(
        (item) => item.title === status,
      );

      if (styleOption && property === "color") {
        return styleOption.color ? styleOption.color : "";
      }
      if (styleOption && property === "icon") {
        return styleOption.icon ? styleOption.icon : "";
      }
    },
    /**
     * Saves and replaces the latest status with the newly saved status
     *
     * Triggers a custom event to reflect the change and update the status list
     */
    async saveIssueStatus() {
      this.saveStatusLoading = true;
      this.turbineIssuesLoading = true;
      // Set timezone of selected time without converting time to new timezone offset
      const selectedTime = DateTime.fromJSDate(this.date);
      var rezoned = selectedTime.setZone(this.timeZone, {
        keepLocalTime: true,
      });
      // Then convert the rezoned time to UTC
      const utc = rezoned.setZone("UTC");

      if (this.selected !== this.latestStatus) {
        let updateObj = {
          issueId: this.$route.params.issueId,
          statusDefId: this.selected.id,
        };
        if (this.date && !this.datetimeInvalid) {
          updateObj.ts = utc.toFormat("yyyy-LL-dd HH:mm:ss");
        } else if (
          (this.selected.name_external === "Resolved" ||
            this.selected.name_external === "Fixed") &&
          !this.date
        ) {
          this.datetimeInvalid = true;
        }
        if (this.isPreviousRecommendation) {
          updateObj.turbineRecommendationId =
            this.newestPendingImplementationStatus.turbine_recommendation.id;
          if (this.newestPendingImplementationStatus.attachments) {
            updateObj.previousAttachments =
              this.newestPendingImplementationStatus.attachments;
          }
          if (this.attachments.length > 0) {
            updateObj.attachment = this.attachments;
            updateObj.kind = "status";
          }
        }

        if (this.isFixed) {
          updateObj.ts = this.latestStatus.turbine_mod.ts;
          updateObj.turbine_mod_id = this.latestStatus.turbine_mod.id;
          if (this.attachments) {
            updateObj.attachment = this.attachments;
            updateObj.kind = "status";
          }
        }
        if (this.comment !== "") {
          updateObj.comment = this.comment;
        }
        if (this.isSuperseded) {
          updateObj.superseded_by_turbine_issue_id = this.issueSelected.id;
        }
        if (
          this.selected.id === 7 ||
          this.selected.id === 8 ||
          this.selected.id === 9
        ) {
          updateObj.mod = this.selectedTurbineModDef;
          updateObj.turbine_mod_id = this.selectedTurbineModDef.id;
          if (this.modValue !== null || !isNaN(this.modValue)) {
            updateObj.value = this.modValue;
          }
          if (this.modNotes !== "") {
            updateObj.modNotes = this.modNotes;
          }
        }
        this.turbineIssuesLoading = true;

        // Data sent to Google Analytics
        let eventLabel = `issue_status_changed_to_${this.selected.name_internal}`;
        if (this.clickCount < clickCountLimit) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: eventLabel.toLowerCase(),
            value: this.clickCount,
          });
        }

        await this.updateIssueStatus(updateObj);
        /**
         * Tells the parent component to update the status list to include the newly saved status
         *
         * Also updates the latest status to reflect the newly saved status
         */
        await this.getIssueStatusData({
          issueId: this.$route.params.issueId,
        });
        this.$emit("updateWidgetCards");
        this.saveStatusLoading = false;
      } else {
        this.saveStatusLoading = false;
      }
    },
    /**
     * Cancel status selection and revert to latest status being shown
     */
    cancelStatusChange() {
      this.attachments = null;
      this.selected = this.latestStatus;
      this.issueSelected = { name: "Select issue..." };
      this.isSuperseded = false;
      this.showSave = false;
      this.showLastUpdated = true;
      this.isFixed = false;
      this.isPreviousRecommendation = false;
      this.isNewRecommendation = false;
      this.datetimeInvalid = false;
      this.date = null;
    },
    verifyAsFixed() {
      this.showSave = true;
      this.showLastUpdated = false;
      this.selected = this.statuses.find((status) => status.id === 8); // Fixed id
      this.isFixed = true;
    },
    previousRecommendation() {
      this.showSave = true;
      this.showLastUpdated = false;
      this.selected = this.statuses.find((status) => status.id === 5); // Pending implementation id
      this.isPreviousRecommendation = true;
    },
    newRecommendation() {
      this.showSave = false;
      this.showLastUpdated = false;
      this.selected = this.statuses.find((status) => status.id === 5); // Pending implementation id
      this.$nextTick(() => {
        this.updateRecommendationSelection("New recommendation");
      });

      this.isNewRecommendation = true;
    },
    toggleUploadDialog(showDialog) {
      this.showDialog = showDialog;
    },
    setFile(chosenFile) {
      this.attachments = chosenFile;
    },
    removeAttachment(attachment) {
      this.attachments = this.attachments.filter((item) => item !== attachment);
    },
    checkDateValidity(value) {
      if (
        typeof value == "string" &&
        new Date(value).toString() === "Invalid Date"
      ) {
        this.datetimeInvalid = true;
      } else if (
        value &&
        (new Date(value) < new Date(this.lossesGains.issue_start_ts) ||
          new Date(value) > new Date())
      ) {
        this.datetimeInvalid = true;
      } else if (
        this.date &&
        this.date < new Date(this.lossesGains.issue_start_ts)
      ) {
        this.datetimeInvalid = true;
      } else {
        this.datetimeInvalid = false;
      }
    },
    disabledDates(date) {
      return (
        date < new Date(this.lossesGains.issue_start_ts) || date > new Date()
      );
    },
  },
  watch: {
    selected: {
      handler(value) {
        if (value) {
          if (this.selected.require_timestamp_input) {
            if (this.selected === this.latestStatus) {
              this.showDatepicker = false;
              this.showSave = false;
            } else {
              this.showDatepicker = true;
            }
          } else {
            this.showDatepicker = false;
          }

          if (this.selected === this.latestStatus) {
            this.showSave = false;
            this.showComment = false;
            this.showLastUpdated = true;
          } else if (!this.isNewRecommendation) {
            this.showSave = true;
            this.showComment = true;
            this.showLastUpdated = false;
          }
          if (
            this.selected.definition_name_internal !== "Pending implementation"
          ) {
            this.updateRecommendationSelection(null);
          }
          if (
            this.selected.name_external === "Superseded" &&
            this.selected.name_external !== this.latestStatus
          ) {
            this.isSuperseded = true;
            if (!this.wasSuperseded) {
              this.$emit("statusSelected", this.selected);
            }
          } else {
            this.isSuperseded = false;
          }
        }
      },
    },
    cancel: {
      handler(value) {
        if (value) {
          this.showSave = false;
          this.showComment = false;
          this.cancelStatusChange();
          this.clickedCancel(false);
        }
      },
    },
    watchLatestStatus: {
      immediate: true,
      handler(status) {
        if (status && Object.keys(status).length > 0) {
          const updateDate = new Date(status.created_ts);
          const localDateForm = updateDate.toISOString();
          const splitDate = localDateForm.split("T");
          const latestDateDisplay = `Last updated ${splitDate[0]}`;

          this.selected = status;
          this.dateLastUpdated = latestDateDisplay;
          this.showSave = false;
          this.saveStatusLoading = false;
        }
      },
    },
    suggestedRecommendation: {
      immediate: true,
      handler(data) {
        if (
          this.suggestedRecommendation &&
          Object.keys(this.suggestedRecommendation).length > 0
        ) {
          this.selectedTurbineModDef =
            this.suggestedRecommendation?.turbine_mod_def;
          this.modValue = this.suggestedRecommendation.value;
        }
      },
    },
  },
  async beforeMount() {
    await this.getTurbineModDefs();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";

.issue-status {
  position: relative;
  padding: 1.75rem 1rem;
  border-radius: 8px;

  .label {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: "Museo Sans Rounded";
  }

  .issue-status-select {
    width: 280px;
  }

  .by-text {
    font-size: 14px;
  }

  .last-update-date {
    color: var(--v-black5-base);
  }

  .issue-select {
    width: 280px;
  }

  .superseding-issue-link {
    font-size: 14px;
  }

  .superseding-issue-link:hover {
    color: rgba(50, 93, 171);
    text-decoration: underline;
  }
}

::v-deep {
  .date-error {
    .mx-input {
      border-color: red;
    }
  }
}
::v-deep {
  --vs-controls-color: var(--v-text-base);
  --vs-border-color: var(--v-text-base);
  --vs-dropdown-bg: var(--v-background-base);
  --vs-dropdown-color: var(--v-text-base);
  --vs-dropdown-option-color: var(--v-text-base);
  --vs-selected-color: var(--v-text-base);
  --vs-dropdown-option--active-bg: var(--v-hover-base);
  --vs-dropdown-option--active-color: var(--v-text-base);
}
::v-deep {
  .vs__dropdown-toggle {
    border: none;
  }
  .vs__dropdown-menu {
    border: none !important;
    background-color: var(--v-secondary-base);
  }
  .vs__dropdown-toggle[aria-expanded="true"] {
    .vs__open-indicator {
      rotate: 90deg;
    }
  }

  .issue-status-select {
    .vs__dropdown-toggle {
      background-color: var(--v-secondary-base);
      cursor: pointer;
    }
  }
  .theme--dark .vs__dropdown-menu {
    background-color: var(--v-black10-base) !important;
    background: var(--v-black10-base) !important;
  }
}

::v-deep {
  .issue-select {
    .vs__dropdown-toggle {
      background-color: var(--v-secondary-base);
      cursor: pointer;
      max-height: 2.3rem;
    }
    .vs__dropdown-toggle[aria-expanded="true"] {
      .vs__selected {
        color: var(--v-text-base);
      }
    }
    .vs__search {
      width: 1px;
    }
    .vs__selected {
      line-height: 1rem;
      margin: 0;
    }
    .vs__dropdown-menu {
      overflow-x: hidden;
      margin: 0;
      padding: 0;
    }
  }
}

.unconfirmed-text {
  font-size: 1.125rem;
  color: $red1;
}

.viewer-dialog {
  position: relative;
}
.viewer-width {
  width: 100%;
  z-index: 1500;
}
.canvas-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.spinner-div {
  position: absolute;
  z-index: 1550;
  top: 25%;
  left: 50%;
}
</style>
