<template>
  <PopUp :content="[computedPopUpData]">
    <template v-slot:hover>
      <v-icon style="transform: scale(0.8)" class="ml-1">{{
        informationIcon
      }}</v-icon>
    </template>
    <template v-slot:content>
      <div class="tooltip-font-size pa-2">
        <div v-if="computedPopUpData.modelType">
          {{ computedPopUpData.type }} model type:
          {{ computedPopUpData.modelType }}
        </div>
        <div v-if="computedPopUpData.data_start_ts">
          {{ computedPopUpData.type }} data start:
          {{ computedPopUpData.data_start_ts }} (UTC)
        </div>
        <div v-if="computedPopUpData.data_end_ts">
          {{ computedPopUpData.type }} data end:
          {{ computedPopUpData.data_end_ts }} (UTC)
        </div>
        <div v-if="computedPopUpData.total_energy_string">
          {{ computedPopUpData.total_energy_string }}
        </div>
        <div
          v-if="computedPopUpData.footNote"
          class="pt-2"
          style="font-size: 12px"
        >
          {{ computedPopUpData.footNote }}
        </div>
      </div>
    </template>
  </PopUp>
</template>

<script>
import { mapState } from "vuex";
import PopUp from "@/components/PopUp";
import { roundToString } from "@/helpers/functions";
import { informationIcon } from "@/helpers/variables";

export default {
  name: "LossGainsPopUp",
  components: {
    PopUp,
  },
  data() {
    return {
      informationIcon,
    };
  },
  props: {
    toggle: Boolean,
    latestStatusName: String,
  },
  computed: {
    ...mapState({
      lossesGains: (state) => state.issueDetail.lossesGains,
    }),
    computedPopUpData() {
      if (!this.toggle && this.latestStatusName !== "Fixed") {
        // Cumulative + not fixed
        return this.computeCumulativeLossData();
      } else if (this.toggle && this.latestStatusName !== "Fixed") {
        // Annual + not fixed
        return this.computeAnnualLossData();
      } else if (!this.toggle && this.latestStatusName === "Fixed") {
        // Cumulative + fixed
        return this.computeCumulativeGainData();
      } else if (this.toggle && this.latestStatusName === "Fixed") {
        // Annual + fixed
        return this.computeAnnualGainData();
      }
      return null;
    },
  },
  methods: {
    computeCumulativeLossData() {
      // Logic to compute data when issue is not fixed and view is cumulative
      let loss_period_total_energy_mwh = this.lossesGains
        ? `${roundToString(
            this.lossesGains.loss_period_total_energy_mwh,
            -1,
          )} MWh`
        : "TBD";
      return {
        modelType: this.lossesGains?.loss_model_type,
        data_start_ts: this.lossesGains?.loss_data_start_ts,
        data_end_ts: this.lossesGains?.loss_data_end_ts,
        loss_period_total_energy_mwh: loss_period_total_energy_mwh,
        total_energy_string: `Total energy in loss period: ${loss_period_total_energy_mwh}`,
        type: "Loss",
      };
    },
    computeAnnualLossData() {
      // Logic to compute data when issue is not fixed and view is annual
      let aep_mwhpyr = `${roundToString(
        this.lossesGains?.aep_mwhpyr,
        -1,
      )} MWh/yr`;
      return {
        modelType: this.lossesGains?.loss_model_type,
        data_start_ts: this.lossesGains?.aep_data_start_ts,
        data_end_ts: this.lossesGains?.aep_data_end_ts,
        aep_mwhpyr: aep_mwhpyr,
        total_energy_string: `AEP: ${aep_mwhpyr}`,
        type: "Loss",
      };
    },
    computeCumulativeGainData() {
      // Logic to compute data when issue is fixed and view is cumulative
      let gain_period_total_energy_mwh = `${roundToString(
        this.lossesGains?.gain_period_total_energy_mwh,
        -1,
      )} MWh/yr`;
      return {
        modelType: this.lossesGains?.gain_model_type
          ? this.lossesGains.gain_model_type
          : `${this.lossesGains?.loss_model_type}*`,
        data_start_ts: this.lossesGains?.gain_data_start_ts,
        data_end_ts: this.lossesGains?.gain_data_end_ts,
        gain_period_total_energy_mwh: gain_period_total_energy_mwh,
        total_energy_string: `Total energy in loss period: ${gain_period_total_energy_mwh}`,
        type: "Gain",
        footNote: this.lossesGains?.gain_model_type
          ? null
          : "*No gain model has been trained, so the loss model is used to estimate gains.",
      };
    },
    computeAnnualGainData() {
      // Logic to compute data when issue is fixed and view is annual
      let aep_mwhpyr = `${roundToString(
        this.lossesGains?.aep_mwhpyr,
        -1,
      )} MWh/yr`;
      return {
        modelType: this.lossesGains?.gain_model_type
          ? this.lossesGains.gain_model_type
          : `${this.lossesGains.loss_model_type}*`,
        data_start_ts: this.lossesGains?.aep_data_start_ts,
        data_end_ts: this.lossesGains?.aep_data_end_ts,
        aep_mwhpyr: aep_mwhpyr,
        total_energy_string: `AEP: ${aep_mwhpyr}`,
        type: "Gain",
        footNote: this.lossesGains?.gain_model_type
          ? null
          : "*No gain model has been trained",
      };
    },
  },
};
</script>

<style>
.tooltip-font-size {
  font-size: 0.75rem;
}
</style>
