<template>
  <div :id="id" v-resize:debounce.100="onResize" className="plotly-figure" />
</template>
<script>
import Plotly from "plotly.js-dist-min";
import methods, { camelize, events } from "../helpers/plotly.js";

export default {
  name: "PlotlyJS",
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
    },
    layout: {
      type: Object,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      scheduled: null,
      innerLayout: {
        ...this.layout,
        modebar: {
          bgcolor: `rgba(0,0,0,0)`,
          color: this.$vuetify.theme.isDark
            ? `rgba(255,255,255,0.3)`
            : `rgba(0,0,0,0.3)`,
          activecolor: this.$vuetify.theme.isDark
            ? `rgba(255,255,255,0.7)`
            : `rgba(0,0,0,0.7)`,
        },
        paper_bgcolor: this.$vuetify.theme.isDark ? "#121212" : "transparent",
        plot_bgcolor: this.$vuetify.theme.isDark ? "#121212" : "transparent",
        yaxis: {
          ...this.layout.yaxis,
          gridcolor: this.$vuetify.theme.isDark
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)",
          zerolinecolor: this.$vuetify.theme.isDark
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)",
          tickfont: {
            color: this.$vuetify.theme.isDark ? "#c9d1d9" : "",
          },
        },
        xaxis: {
          ...this.layout.xaxis,
          gridcolor: this.$vuetify.theme.isDark
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)",
          zerolinecolor: this.$vuetify.theme.isDark
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)",
          linecolor: this.$vuetify.theme.isDark
            ? "rgba(255,255,255,0.1)"
            : "rgba(0,0,0,0.1)",
        },
        font: {
          color: this.$vuetify.theme.isDark ? "#c9d1d9" : "",
        },
      },
    };
  },
  computed: {
    options() {
      const optionsFromAttrs = Object.keys(this.$attrs).reduce((acc, key) => {
        acc[camelize(key)] = this.$attrs[key];
        return acc;
      }, {});
      return {
        responsive: false,
        displaylogo: this.displaylogo,
        ...optionsFromAttrs,
      };
    },
  },
  beforeDestroy() {
    events.forEach((event) => this.$el.removeAllListeners(event.completeName));
    Plotly.purge(this.$el);
  },
  methods: {
    ...methods,
    onResize() {
      Plotly.Plots.resize(this.$el);
    },
    schedule(context) {
      const { scheduled } = this;
      if (scheduled) {
        scheduled.replot = scheduled.replot || context.replot;
        return;
      }
      this.scheduled = context;
      this.$nextTick(() => {
        const {
          scheduled: { replot },
        } = this;
        this.scheduled = null;
        if (replot) {
          this.react();
          return;
        }
        this.relayout(this.innerLayout);
      });
    },
    toImage(options) {
      const allOptions = Object.assign(this.getPrintOptions(), options);
      return Plotly.toImage(this.$el, allOptions);
    },
    downloadImage(options) {
      const filename = `plot--${new Date().toISOString()}`;
      const allOptions = Object.assign(
        this.getPrintOptions(),
        { filename },
        options,
      );
      return Plotly.downloadImage(this.$el, allOptions);
    },
    getPrintOptions() {
      const { $el } = this;
      return {
        format: "png",
        width: $el.clientWidth,
        height: $el.clientHeight,
      };
    },
    react() {
      Plotly.react(this.$el, this.data, this.innerLayout, this.options);
    },
    onPlotlyPointClick(event) {
      const annotateTs = event.points[0]?.customdata[0];
      const annotateTurbine = event.points[0]?.customdata[1];
      const params = {
        annotate_ts: annotateTs,
        annotate_turbine: annotateTurbine,
      };
      this.$emit("plotlyPointClicked", params);
    },
  },
  mounted() {
    Plotly.react(this.$el, this.data, this.innerLayout, this.options);
    events.forEach((evt) => {
      this.$el.on(evt.completeName, evt.handler(this));
    });

    // Add a click event listener for analytics plots
    this.$el.on("plotly_click", this.onPlotlyPointClick);
  },
  watch: {
    data: {
      handler() {
        this.schedule({ replot: true });
      },
      deep: true,
    },
    options: {
      handler(value, old) {
        if (JSON.stringify(value) === JSON.stringify(old)) {
          return;
        }
        this.schedule({ replot: true });
      },
      deep: true,
    },
    layout(layout) {
      this.innerLayout = { ...layout };
      this.schedule({ replot: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.plotly-figure {
  height: 100%;
  width: 100%;
}
</style>
