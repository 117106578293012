<template>
  <WaitWrapper :loading="isMapLoading">
    <l-map
      ref="map"
      :zoom="mapData.zoom"
      :bounds="bounds"
      :center="center"
      :options="{ attributionControl: false, tap: false }"
      :style="mapStyle"
    >
      <l-tile-layer :url="mapData.url" />
      <l-marker
        v-for="(item, index) in mapData.markers"
        :key="'marker-' + index"
        :lat-lng="item.position"
        :icon="getIcon(item)"
        @l-add="$event.target.openPopup()"
        @mouseover="selectItem(item)"
        @mouseleave="unselectItem(item)"
      >
        <l-popup>
          <router-link
            v-if="item.mapInfoRoute"
            :to="item.mapInfoRoute"
            class="link"
          >
            <h5 class="map-info-text">{{ item.mapInfoTitle }}</h5>
          </router-link>
          <h5 class="map-info-text" v-else>{{ item.mapInfoTitle }}</h5>
          <div v-if="item.turbine_model" class="map-info-text">
            {{ item.turbine_model }}
          </div>
          <div v-if="item.mapInfoDesc" class="map-info-text">
            {{ item.mapInfoDesc }}
          </div>
          <div v-if="item.n_alarms != null" class="map-info-text">
            Alarms: {{ item.n_alarms }}
          </div>
          <div v-if="item.value != null" class="map-info-text">
            Value: {{ item.value }}
          </div>
        </l-popup>
      </l-marker>
    </l-map>
  </WaitWrapper>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import WaitWrapper from "@/components/WaitWrapper";

export default {
  name: "MapView",
  props: {
    mapData: Object,
    isMapLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: String,
      required: false,
      default: "450px",
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    WaitWrapper,
  },
  data() {
    return {
      bounds: null,
      center: [0, 0],
    };
  },
  computed: {
    mapHeight() {
      return this.height;
    },
    mapStyle() {
      return { height: this.mapHeight, width: "100%" };
    },
  },
  watch: {
    mapData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.center = val.center;
        if (val.markers && val.markers.length > 0) {
          if (val.markers.length !== 1) {
            this.bounds = val.markers.map((m) => m.position);
          } else {
            this.center = [
              val.markers[0].position.lat,
              val.markers[0].position.lng,
            ];
          }
        } else {
          this.bounds = null;
        }
      },
    },
  },
  methods: {
    selectItem(item) {
      item.selected = true;
    },
    unselectItem(item) {
      item.selected = false;
    },
    getIcon(item) {
      return L.divIcon({
        className: "my-custom-pin",
        html: item.selected
          ? `
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
              <circle cx="14" cy="14" r="9" fill="${item.color}"/>
              <circle cx="14" cy="14" r="8" stroke="white" stroke-width="2"/>
            </g>
            <defs>
              <filter id="filter0_d" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.175243 0 0 0 0 0.366508 0 0 0 0 0.858333 0 0 0 0.37 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
              </filter>
            </defs>
          </svg>
        `
          : `
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <circle cx="14" cy="14" r="9" fill="${item.color}"/>
              <circle cx="14" cy="14" r="8" stroke="white" stroke-width="2"/>
            </g>
          </svg>
        `,
      });
    },
  },
};
</script>

<style>
.leaflet-popup-content {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
</style>
<style scoped lang="scss">
.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.map-info-text {
  text-align: center;
}
</style>
