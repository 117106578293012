<template>
  <div class="at-a-glance-container">
    <div
      class="d-flex justify-center align-center at-a-glance-container__spinner"
      v-if="lossesGainsLoading || issuesLoading"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div>
      <header class="d-flex align-center justify-space-between flex-wrap">
        <h4 class="at-a-glance-title ma-0 ml-1 pb-2">At a Glance</h4>
        <div
          class="d-flex mr-1 switch-container switch-labels"
          v-if="page === 'issueDetail'"
        >
          <label class="mr-3">Cumulative</label>
          <v-switch inset dense v-model="toggle" class="toggle"></v-switch>
          <label>Annual</label>
          <LossGainsPopUp
            :toggle="toggle"
            :latestStatusName="latestStatusName"
            class="losses-gains-popup"
          />
        </div>
        <slot name="header"> </slot>
      </header>
      <hr />
      <content>
        <div class="mt-0">
          <div v-if="page === 'issueDetail'" class="mb-3">
            <div class="orientation-header ml-1 mb-3 mt-2">
              <p class="subtitle mb-0">IMPACT</p>
            </div>
            <div class="d-flex align-baseline">
              <span class="content-header" :class="impactColor">{{
                computeImpactString
              }}</span>
            </div>
            <div class="d-flex justify-space-between mt-1 align-end">
              <div class="impact-caption ml-1 grey--text">
                {{ computeImpactTs }}
              </div>
              <div class="ml-1">
                <v-btn outlined small rounded @click="goToCheckDetail"
                  >SEE SIMILAR ISSUES</v-btn
                >
              </div>
            </div>
          </div>
        </div>
        <slot name="content"></slot>
      </content>
      <hr />
      <footer>
        <div v-if="page === 'issueDetail'">
          <div class="mt-2">
            <div class="subtitle latest-status">
              STATUS: {{ latestStatusName }}
            </div>
            <div class="d-flex flex-wrap justify-space-between mt-5">
              <div class="mt-1 mb-1">
                <v-btn
                  outlined
                  rounded
                  small
                  class="content-btn"
                  @click="showIssueDialog = true"
                >
                  {{ statusButtonContent }}
                </v-btn>
              </div>
              <div class="last-updated-caption grey--text ml-1">
                {{ latestStatusLastUpdatedString }}
              </div>
            </div>
          </div>
          <v-dialog v-model="showIssueDialog" max-width="550px">
            <v-card>
              <IssueStatusWidget
                :userHasInternalAccess="userHasInternalAccess"
                :userHasWriteAccess="userHasWriteAccess"
                :selectedStatus="selectedStatus"
              />
              <v-card-actions>
                <v-btn @click="showIssueDialog = false">Close Dialog</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div
            @click="showIssueDialog = false"
            class="backdrop"
            v-if="showIssueDialog"
          ></div>
        </div>
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
import { informationIcon } from "@/helpers/variables";
import { mapState, mapActions } from "vuex";
import { roundToString } from "@/helpers/functions";
import IssueStatusWidget from "@/components/IssueStatusWidget";
import LossGainsPopUp from "@/components/SummaryCards/LossGainsPopUp.vue";

export default {
  name: "AtAGlance",
  props: {
    page: {
      type: String,
      required: false,
      default: null,
    },
    lossesGainsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    issuesLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    IssueStatusWidget,
    LossGainsPopUp,
  },
  data() {
    return {
      toggle: false, // false for Cumulative, true for Annual
      selectedStatus: null,
      showIssueDialog: false,
      informationIcon,
    };
  },
  computed: {
    ...mapState({
      updateIssueStatusResponse: (state) =>
        state.issueDetail.updateIssueStatusResponse,
      statusDataloading: (state) =>
        state.issueDetail.loading.getIssueStatusData,
      issueStatuses: (state) => state.issueDetail.issueStatuses,
      statusHistory: (state) => state.issueDetail.statusHistory,
      latestStatus: (state) => state.issueDetail.latestStatus,
      cancel: (state) => state.issueDetail.cancel,
      lossesGains: (state) => state.issueDetail.lossesGains,
      issueData: (state) => state.issueDetail.issueData,
      confirmedIssues: (state) => state.issues.confirmedIssues,
      turbineIssues: (state) => state.issues.turbineIssues,
      turbineModDefs: (state) => state.issueDetail.turbineModDefs,
      suggestedRecommendation: (state) =>
        state.issueDetail.suggestedRecommendation,
      clickCount: (state) => state.app.clickCount,
    }),
    statuses() {
      if (this.issueStatuses?.length > 0) {
        return this.issueStatuses;
      } else {
        return ["Loading..."];
      }
    },
    impactColor() {
      if (this.latestStatusName == "Fixed") {
        return "gain";
      } else {
        return "loss";
      }
    },
    userHasWriteAccess() {
      return this.issueData?.user_has_write_access;
    },
    userHasInternalAccess() {
      return this.issueData?.user_has_internal_access;
    },
    computeImpactString() {
      let impactString = "";
      if (!this.toggle) {
        impactString = `$${this.lossUSD} (${this.lossMWh} MWh)`;
      } else {
        impactString = `$${this.aepLossUSD}/yr`;
      }
      return impactString;
    },
    computeImpactTs() {
      let tsString = "";
      if (this.lossesGains && Object.keys(this.lossesGains).length > 0) {
        tsString = this.lossesGains.issue_start_ts + " (UTC)";
      } else {
        tsString = "Unknown";
      }
      return `Since ${tsString} `;
    },
    aepLossUSD() {
      if (
        this.lossesGains?.aep_loss_upper_usdpyr &&
        this.aepLossPct !== 0.0 &&
        this.aepLossPct !== "0.0"
      ) {
        return roundToString(this.lossesGains.aep_loss_upper_usdpyr, -1);
      } else {
        return 0;
      }
    },
    lossUSD() {
      if (
        this.lossesGains?.loss_upper_usd &&
        this.aepLossPct !== 0.0 &&
        this.aepLossPct !== "0.0"
      ) {
        return roundToString(this.lossesGains.loss_upper_usd, -1);
      } else {
        return 0;
      }
    },
    gainUSD() {
      if (
        this.lossesGains?.gain_upper_usd &&
        this.aepGainPct !== 0.0 &&
        this.aepGainPct !== "0.0"
      ) {
        return roundToString(this.lossesGains.gain_upper_usd, -1);
      } else {
        return 0;
      }
    },
    lossMWh() {
      if (
        this.lossesGains &&
        (this.lossesGains.loss_mwh || this.lossesGains.loss_mwh === 0)
      ) {
        if (this.aepLossPct === 0.0 || this.aepLossPct === "0.0") {
          return 0;
        } else {
          return roundToString(+this.lossesGains.loss_mwh, 0);
        }
      } else {
        return "TBD";
      }
    },
    gainMWh() {
      if (
        this.lossesGains &&
        (this.lossesGains.gain_mwh || this.lossesGains.gain_mwh === 0)
      ) {
        if (this.aepGainPct === 0.0 || this.aepGainPct === "0.0") {
          return 0;
        } else {
          return roundToString(this.lossesGains.gain_mwh, -1);
        }
      } else {
        return "TBD";
      }
    }, // if (this.lossPct === "TBD") { show alternate status display}
    latestStatusName() {
      return (
        this.latestStatus.name_external ||
        this.latestStatus.definition_name_external
      );
    },
    statusButtonContent() {
      if (
        ["Unconfirmed (not visible)", "Needs review"].includes(
          this.latestStatusName,
        )
      ) {
        return "CONFIRM ISSUE";
      } else if (this.latestStatusName === "New") {
        return "MARK IN PROGRESS";
      } else if (this.latestStatusName === "In progress") {
        return "MARK FIXED";
      } else if (this.latestStatusName === "Fixed") {
        return "COMMENT";
      } else if (
        [
          "Superseded",
          "Dismissed",
          "Deferred",
          "Dismissed (internal)",
          "Invalidated",
        ].includes(this.latestStatusName)
      ) {
        return "MARK NEW";
      } else {
        return "";
      }
    },
    latestStatusLastUpdatedString() {
      return `Last updated ${this.latestStatus.created_ts} (UTC) \n
      by ${this.latestStatus.user_full_name}`;
    },
  },
  methods: {
    getContentSubHeader() {
      if (this.page === "issueDetail") {
        return "Impact";
      }
    },
    openIssueDialog(item) {
      this.selectedStatus = item.name_external || item.definition_name_external;
      this.showIssueDialog = true;
    },
    closeIssueDialog() {
      this.showIssueDialog = false;
    },
    goToCheckDetail() {
      this.$router.push({
        path: `/sites/${this.issueData.turbine.farm_id}/checks/${this.issueData.turbine_issue_def_id}`,
      });
    },
  },
};
</script>
<style lang="scss">
.losses-gains-popup {
  .popup {
    max-width: 350px;
    left: -300px;
    top: -78px;
    padding: 0.75rem 1rem;
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/scss/_variables";
// Colors to be used for the boxes
$colors: $red, $green;

.at-a-glance-container {
  position: relative;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    border-radius: 8px;
    opacity: 0.4;
    z-index: 100;
  }

  header .v-input {
    margin: 0;
    padding: 0;
  }
  hr {
    border: none;
    height: 2px;
    margin: 0;
    background-color: var(
      --v-converterBorders-base
    ); // Vuetify3 this is rgb(var(--v-v-theme-mediumGray))
  }
}

.switch-container {
  margin-bottom: -1.5rem;
  label {
    margin-top: 0.3rem;
  }
}

.toggle {
  transform: scale(0.75);
}

.info-circle {
  color: var(--v-primary-base) !important;
}

.at-a-glance-title {
  font:
    1.75rem "Museo Sans Rounded",
    sans-serif;
  font-weight: bold;
}

.content-header {
  font-size: 1.75rem;
  font-weight: bold;
}

.subtitle {
  text-transform: uppercase;
  /* Adjust margin to align with dollar amount if necessary */
}
.latest-status {
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
}

.impact-caption,
.last-updated-caption {
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
}
.impact-caption {
  line-height: 1.2;
}
.last-updated-caption {
  max-width: 255px;
}

.issue-status-select {
  transform: scale(0.75);
}

.gain {
  color: $green;
}
.loss {
  color: $red;
}
</style>
