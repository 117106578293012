var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"systems-tab-container"},[_c('TableHeaderFilter',{attrs:{"headers":_vm.headers,"selectedHeaders":_vm.selectedHeaders,"menuActivator":"#org-dash-systems-col-filter"},on:{"changeHeaders":_vm.changeSelectedHeaders}}),_c('DynamicTable',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.systems,"hasFooter":true,"loading":_vm.loading,"tabSelected":"systems","menuActivator":"org-dash-systems-col-filter"},scopedSlots:_vm._u([{key:"header_status",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},_vm._l((header.text),function(value,key){return _c('div',{key:key},[(key === 'icon')?_c('v-icon',{staticClass:"ml-1 help-icon-color",attrs:{"size":"medium"}},[_vm._v(_vm._s(value))]):_c('div',[_vm._v(_vm._s(value))])],1)}),0)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('div',[_vm._v(_vm._s(_vm.transformName(item.name, "display")))])])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-item"},[_c('v-icon',{attrs:{"color":item.status}},[_vm._v("mdi-circle-medium")])],1)]}},{key:"impact_mwh",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.impact_mwh)+" MWh")]),_c('span',[_vm._v("/")]),_c('span',{staticClass:"ml-1"},[_vm._v("$")]),_vm._v(_vm._s(item.impact_usd)+" ")])]}}])},[void 0],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }