<template>
  <div class="org-dashboard-container">
    <div
      class="export-overlay"
      v-if="isDownloadModalOpen"
      @click="isDownloadModalOpen = false"
    ></div>
    <div class="grid1">
      <div class="grid1-row-1">
        <div class="org-dashboard__subtitle d-flex align-center">
          <p class="mb-0">ORG LOCATION</p>
        </div>
        <div class="org-dashboard__header d-flex align-center">
          <h3 class="main-title mr-2">
            <div
              class="d-flex justify-center align-center main-title-spinner mt-2"
              v-if="loading.getOrgData"
            >
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>{{ orgName }}</div>
          </h3>
          <div>
            <v-tooltip top :open-delay="popupDelay">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openDownloadModal()"
                  class="h100 icon-hover download-icon-color"
                  >mdi-download</v-icon
                >
              </template>
              <span>Export report</span>
            </v-tooltip>
          </div>
          <div v-if="isDownloadModalOpen">
            <div class="download-modal mt-4">
              <div class="d-flex">
                <h4 class="ma-0">Export report</h4>
                <div class="close-icon" @click="isDownloadModalOpen = false">
                  <v-icon>mdi-close</v-icon>
                </div>
              </div>
              <p class="ma-0 export-report-subtitle mb-2">
                Choose a date range for the {{ orgName }} issues summary report
              </p>
              <div class="date-picker-container picker1 mb-2">
                <label for="start-date">Start date:</label>
                <date-picker
                  ref="start-date"
                  type="date"
                  name="start-date"
                  id="start-date"
                  placeholder="Start date"
                  v-model="dateRange.startDate"
                  class="datepicker"
                  :clearable="false"
                  :class="{
                    'error-highlight': !isDateRangeValid,
                  }"
                  @blur="blurDate"
                  @pick="handlePickStartDate"
                >
                  <template v-slot:content="slotProps">
                    <calendar-panel
                      :disabled-date="
                        (date) =>
                          isDateDisabled(date, dateRange.endDate, 'startDate')
                      "
                      :value="slotProps.value"
                      @select="slotProps.emit"
                    ></calendar-panel>
                  </template>
                </date-picker>
              </div>
              <div class="date-picker-container picker2 mt-2">
                <label for="end-date">End date:</label>
                <date-picker
                  ref="end-date"
                  type="date"
                  placeholder="End date"
                  name="end-date"
                  id="end-date"
                  v-model="dateRange.endDate"
                  class="datepicker"
                  :class="{
                    'error-highlight': !isDateRangeValid,
                  }"
                  :clearable="false"
                  @blur="blurDate"
                  @pick="handlePickEndDate"
                >
                  <template v-slot:content="slotProps">
                    <calendar-panel
                      :disabled-date="
                        (date) =>
                          isDateDisabled(date, dateRange.startDate, 'endDate')
                      "
                      :value="slotProps.value"
                      @select="slotProps.emit"
                    ></calendar-panel>
                  </template>
                </date-picker>
              </div>
              <div
                class="mt-1"
                :class="!isDateRangeValid ? 'date-error' : 'success-msg'"
                v-if="!isDateRangeValid || showSuccessMsg"
              >
                {{ modalMsg }}
              </div>
              <div class="d-flex justify-end mt-4 export-btn">
                <v-btn
                  color="green"
                  :disabled="!isDateRangeValid || loadingOrgIssuesReport"
                  :loading="loadingOrgIssuesReport"
                  @click="exportReport"
                  >Export</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid1-row-2-col-1 site-condition-cards">
        <div class="availability-card plot-figure-container">
          <HealthWidget
            :nCriticalIssues="nOpenReliablilityIssues"
            :name="'SITE RELIABILITY HEALTH'"
            :loading="loading.getOrgData"
          ></HealthWidget>
        </div>
      </div>
      <div class="grid1-row-2-col-2 site-condition-cards">
        <div class="performance-card plot-figure-container">
          <HealthWidget
            :nCriticalIssues="nOpenPerformanceIssues"
            :name="'SITE PERFORMANCE'"
            :loading="loading.getOrgData"
          ></HealthWidget>
        </div>
      </div>
      <div class="grid1-row-2-col-3">
        <div class="ml-1">
          <AtAGlance :page="'org'">
            <template v-slot:content>
              <div
                class="content-container d-flex flex-column justify-space-between"
              >
                <div class="d-flex justify-space-between align-baseline mt-1">
                  <div class="orientation-header">
                    <p>ALL ISSUES</p>
                  </div>
                  <div
                    class="d-flex flex-row align-baseline ma-0 pa-0 switch-labels"
                  >
                    <label class="mr-3">Cumulative</label>
                    <v-switch
                      inset
                      hide-details
                      dense
                      v-model="lossGainToggle"
                      class="toggle ma-0 pa-0"
                    ></v-switch>
                    <label>Annual</label>
                  </div>
                </div>
                <div class="issues-summary">
                  <div class="mb-3">
                    <p class="content-body error--text">
                      {{ criticalIssuesCount }} critical issues
                    </p>
                  </div>
                  <div class="d-flex justify-space-between align-end mb-2">
                    <p class="at-a-glance-body-footer">
                      <span class="new-issues">{{ newIssuesCount }} new</span
                      ><span class="mr-1">,</span>
                      <span class="in-progress-issues"
                        >{{ inProgressIssuesCount }} in progress</span
                      ><span class="mr-1">,</span>
                      <span class="closed-issues"
                        >{{ closedIssuesCount }} closed</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div class="mt-2">
                <div class="mb-1 text-content">Total opportunity</div>
                <div class="d-flex justify-space-between align-end">
                  <div class="mb-0 footer-body">
                    ${{ aepPotentialUsd }}/year
                  </div>
                  <div :class="impactColor" class="text-content">
                    <div v-if="lossGainToggle">
                      <div>+ ${{ aepGainUsd }}/year</div>
                      <div>{{ aepGainEnergy }} MWh/year</div>
                    </div>
                    <div class="d-flex flex-column" v-else>
                      <div>+ ${{ cumulativeGainUsd }}</div>
                      <div>{{ cumulativeGainEnergy }} MWh</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </AtAGlance>
        </div>
      </div>
      <div class="grid1-row-3 plot-figure-container">
        <div
          class="d-flex justify-center align-center plot-figure-container__spinner"
          v-if="loadingIssuesOverTimeData"
        >
          <v-progress-circular
            :size="40"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div class="px-4 p-2">
          <div class="d-flex justify-space-between mb-2">
            <h3 class="ma-0 plot-heading">Issues over time</h3>
            <!-- <div class="d-flex align-center mt-1 switch-labels">
                <label class="mr-3 mb-0">Production</label>
                <v-switch
                  inset
                  dense
                  v-model="toggle"
                  disabled
                  hide-details
                  class="toggle ma-0 pa-0"
                  style="transform: scale(0.75)"
                ></v-switch>
                <label class="mb-0 mr-6">Issues</label>
                Comment in when date is determined -->
            <!-- <p class="updated-text ma-0 ">{{ lastUpdatedDate }}</p> -->
            <!-- </div> Comment out until both production and issues plots available -->
          </div>
          <div
            class="figure-container mb-2"
            :style="{ 'background-color': 'transparent' }"
          >
            <Plotly
              v-if="!loadingIssuesOverTimeData"
              :data="issuesOverTimeFigure.data"
              :layout="issuesOverTimeFigure.layout"
              :responsive="true"
              :display-mode-bar="true"
              :showTips="false"
              :use-resize-handler="true"
            />
          </div>
        </div>
      </div>
      <div class="grid1-row-4">
        <div class="new-tab">
          <button
            v-for="tab in tabs"
            :key="tab.id"
            :ref="tab.name"
            :class="{ tablinks: true, active: activeTab === tab.id }"
            @click="openTab(tab.id, tab.name, 'click_event')"
          >
            {{ tab.name }}
          </button>
        </div>
        <div class="org-dashboard-tabbed__section">
          <div id="Sites" v-if="activeTab === 1">
            <OrgDashboardSitesTab
              :sites="issuesBySiteSorted"
              :loading="loading.getIssuesGroupedBySite"
            />
          </div>
          <div id="Issues" class="tabcontent" v-if="activeTab === 2">
            <OrgDashboardIssuesTab
              :issues="issuesByTypeSorted"
              :loading="loading.getChecks"
              :orgId="orgId"
            />
          </div>
          <div id="Systems" class="tabcontent" v-if="activeTab === 3">
            <OrgDashboardSystemsTab
              :systems="systems"
              :orgId="orgId"
              :loading="loadingIssuesByComponent"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="map-container">
      <MapView
        :mapData="mapConfig"
        height="450px"
        :isMapLoading="loading.getIssuesGroupedBySite"
      />
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axiosAPI";
import { mapState, mapActions } from "vuex";
import Plotly from "@/components/Plotly";
import AtAGlance from "@/components/SummaryCards/AtAGlance";
import HealthWidget from "@/components/SummaryCards/HealthWidget";
import OrgDashboardSitesTab from "@/components/NewOrgDashboardComponents/OrgDashboardSitesTab";
import OrgDashboardIssuesTab from "@/components/NewOrgDashboardComponents/OrgDashboardIssuesTab";
import OrgDashboardSystemsTab from "@/components/NewOrgDashboardComponents/OrgDashboardSystemsTab";
import L from "leaflet";
import MapView from "@/components/MapView";
import {
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  popupDelay,
} from "@/helpers/variables";
import { roundToString, setUrlParams } from "@/helpers/functions";
import mixpanel from "mixpanel-browser";
import { COLORS } from "../helpers/colors";
import DatePicker from "vue2-datepicker";
const { CalendarPanel } = DatePicker;
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export default {
  name: "OrgDashboard",
  components: {
    MapView,
    AtAGlance,
    HealthWidget,
    Plotly,
    OrgDashboardSitesTab,
    OrgDashboardIssuesTab,
    OrgDashboardSystemsTab,
    DatePicker,
    CalendarPanel,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "SITES",
        },
        {
          id: 2,
          name: "ISSUES",
        },
        {
          id: 3,
          name: "SYSTEMS",
        },
      ],
      activeTab: 1,
      isForceRefresh: false,
      setUrlParams,
      issuesOverTimeData: null,
      loadingIssuesOverTimeData: false,
      toggle: true,
      roundToString,
      issuesByComponent: [],
      loadingIssuesByComponent: false,
      lossGainToggle: true,
      popupDelay,
      isDownloadModalOpen: false,
      dateRange: {
        startDate: dayjs().subtract(6, "month").toDate(),
        endDate: dayjs().toDate(),
      },
      isDateRangeValid: true,
      modalMsg: "",
      loadingOrgIssuesReport: false,
      showSuccessMsg: false,
    };
  },
  computed: {
    ...mapState({
      orgData: (state) => state.org.orgData,
      loading: (state) => state.org.loading,
      clickCount: (state) => state.app.clickCount,
      userData: (state) => state.user.userData,
      issuesGroupedBySite: (state) => state.org.issuesGroupedBySite,
      issuesGroupedByType: (state) => state.org.checks,
    }),
    orgId() {
      return parseInt(this.$route.params.orgId);
    },
    orgName() {
      if (this.orgData?.name) {
        return this.orgData.name;
      } else {
        return "Org name";
      }
    },
    mapConfig() {
      let mapObj = {
        zoom: 12,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
      };
      if (this.issuesBySite?.length > 0) {
        mapObj.markers = this.issuesBySite;
        return mapObj;
      }
      return mapObj;
    },
    issuesBySite() {
      if (this.issuesGroupedBySite?.length > 0) {
        return this.issuesGroupedBySite.map((item) => {
          const numOfIssues = item.n_open ? item.n_open : 0;
          return {
            id: item.farm_id,
            selected: false,
            site_id: item.farm_id,
            site_name: item.farm_name,
            status: this.setStatusColor(numOfIssues),
            n_open: item.n_open ? item.n_open : "0",
            n_req_attn: item.n_require_external_input
              ? item.n_require_external_input
              : "0",
            unconfirmed: item.n_unconfirmed ? item.n_unconfirmed : "0",
            subscription: item.sow_def_name,
            impact_mwh: this.roundToString(item.aep_loss_mwhpyr_open, 0),
            impact_usd: this.roundToString(item.aep_loss_upper_usdpyr_open, 0),
            data_availability: null, // Not sure what this will be taken from yet
            cod: null, // Not in response yet
            turbine_model: this.findFirstTurbineModelName(
              item.turbine_model_name,
            ),
            power_price: item.latest_power_price_upper_usd
              ? "$" + item.latest_power_price_upper_usd
              : "$" + "0",
            color: item.n_require_external_input > 0 ? COLORS.red : COLORS.blue,
            position: L.latLng(item.latitude_deg, item.longitude_deg),
            mapInfoTitle: item.farm_name,
            mapInfoDesc: `Open issues: ${item.n_open ? item.n_open : 0}`,
            mapInfoRoute: `/sites/${item.farm_id}`,
            lastUpdated: item.last_updated_ts
              ? dayjs(item.last_updated_ts).format("YYYY-MM-DD")
              : "None",
          };
        });
      } else {
        return [];
      }
    },
    issuesByType() {
      if (this.issuesGroupedByType?.length > 0) {
        return this.issuesGroupedByType.map((item) => {
          return {
            id: item.turbine_issue_def_id,
            name: item.name,
            description: item.description,
            newIssues: item.n_new ? item.n_new : "0",
            n_open: item.n_open ? item.n_open : "0",
            ticketsInProgress: item.work_order_id_to_turbine_issue_id
              ? Object.entries(item.work_order_id_to_turbine_issue_id).map(
                  ([workOrderId, turbineIssueId]) => ({
                    work_order_id: workOrderId,
                    turbine_issue_id: turbineIssueId,
                  }),
                )
              : [],
            impact_mwh: this.roundToString(item.aep_loss_mwhpyr_open, 0),
            impact_usd: this.roundToString(item.aep_loss_upper_usdpyr_open, 0),
            totalIssues: item.n ? item.n : "0",
            issuesClosed: item.n_fixed_or_resolved
              ? item.n_fixed_or_resolved
              : "0",
            lastUpdated: item.last_updated_ts
              ? dayjs(item.last_updated_ts).format("YYYY-MM-DD")
              : "None",
          };
        });
      } else {
        return [];
      }
    },
    systems() {
      let issues = [];
      if (this.issuesByComponent?.results?.length > 0) {
        issues = this.issuesByComponent.results.map((issue) => {
          return {
            name: issue.component_name,
            newIssues: issue.n_new,
            status: this.setStatusColor(issue.n_open),
            impact_mwh: this.roundToString(issue.aep_loss_mwhpyr_open, 0),
            impact_usd: this.roundToString(issue.aep_loss_upper_usdpyr_open, 0),
            totalIssues: issue.n ? issue.n : "0",
            issuesOpen: issue.n_open ? issue.n_open : "0",
            issuesClosed: issue.n_fixed_or_resolved
              ? issue.n_fixed_or_resolved
              : "0",
            lastUpdated: issue.last_updated_ts
              ? dayjs(issue.last_updated_ts).format("YYYY-MM-DD")
              : "None",
          };
        });
      }
      return issues;
    },
    issuesOverTimeFigure() {
      if (this.issuesOverTimeData) {
        let issuesOverTimeFigure = this.issuesOverTimeData;
        issuesOverTimeFigure.layout.font = {
          color: this.$vuetify.theme.isDark ? "#fff" : "",
        };
        issuesOverTimeFigure.layout.height = 300;
        issuesOverTimeFigure.layout.margin = { l: 0, b: 0, r: 25, t: 0 };
        issuesOverTimeFigure.layout.autosize = true;
        return issuesOverTimeFigure;
      }
      return null;
    },
    issuesByTypeSorted() {
      if (this.issuesByType.length > 0) {
        return this.sortIssues("type");
      }
      return [];
    },
    issuesBySiteSorted() {
      if (this.issuesBySite.length > 0) {
        return this.sortIssues("site");
      }
      return [];
    },
    criticalIssuesCount() {
      const newIssues = this.orgData.issues_summary?.new?.n;
      const inProgressIssues = this.orgData.issues_summary?.in_progress?.n;
      const sum = newIssues + inProgressIssues;
      return sum > 0 ? sum : "0";
    },
    nOpenReliablilityIssues() {
      return this.orgData?.issues_summary?.open_reliability?.n ?? -1;
    },
    nOpenPerformanceIssues() {
      return this.orgData?.issues_summary?.open_performance?.n ?? -1;
    },
    newIssuesCount() {
      const newIssues = this.orgData.issues_summary?.new
        ? this.orgData.issues_summary.new.n
        : 0;
      return newIssues;
    },
    inProgressIssuesCount() {
      const inProgress = this.orgData.issues_summary?.in_progress
        ? this.orgData.issues_summary.in_progress.n
        : 0;
      return inProgress;
    },
    closedIssuesCount() {
      return this.orgData?.issues_summary?.fixed_or_resolved.n;
    },
    aepPotentialUsd() {
      return this.orgData?.issues_summary?.open?.aep_loss_upper_usdpyr
        ? roundToString(
            this.orgData.issues_summary.open.aep_loss_upper_usdpyr,
            -1,
          )
        : 0;
    },
    aepGainUsd() {
      return this.orgData?.issues_summary?.fixed_or_resolved
        ?.aep_gain_upper_usdpyr
        ? roundToString(
            this.orgData.issues_summary.fixed_or_resolved.aep_gain_upper_usdpyr,
            -1,
          )
        : 0;
    },
    aepGainEnergy() {
      return this.orgData?.issues_summary?.fixed_or_resolved?.aep_gain_mwhpyr
        ? roundToString(
            this.orgData.issues_summary.fixed_or_resolved.aep_gain_mwhpyr,
            -1,
          )
        : 0;
    },
    cumulativeGainUsd() {
      return this.orgData?.issues_summary?.fixed_or_resolved?.gain_upper_usd
        ? roundToString(
            this.orgData.issues_summary.fixed_or_resolved.gain_upper_usd,
            -1,
          )
        : 0;
    },
    cumulativeGainEnergy() {
      return this.orgData?.issues_summary?.fixed_or_resolved?.gain_mwh
        ? roundToString(
            this.orgData.issues_summary.fixed_or_resolved.gain_mwh,
            -1,
          )
        : 0;
    },
    impactColor() {
      return "gain";
    },
  },
  methods: {
    ...mapActions({
      getOrgData: "org/getOrgData",
      getIssuesGroupedByType: "org/getChecks",
      getIssuesGroupedBySite: "org/getIssuesGroupedBySite",
      incrementClickCount: "app/incrementClickCount",
      handleAxiosError: "error/raiseError",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
    }),
    callInitialAPIs(refresh) {
      const orgId = parseInt(this.$route.params.orgId);
      // Retrieving org data, summary, and issues grouped by site (for map population)
      // if (
      //   (Object.keys(this.orgData).length === 0 && !refresh) ||
      //   (Object.keys(this.orgData).length > 0 && refresh) ||
      //   this.orgData?.id !== orgId
      // ) {
      this.getOrgData({
        orgId,
        issuesSummary: 1,
      });
      //  }
      //  if (
      //   this.issuesGroupedBySite?.length === 0 ||
      //   (this.issuesGroupedBySite?.length > 0 && orgId !== this.issuesGroupedBySite[0]?.org_id)
      // ) {
      this.getIssuesGroupedBySite({ orgId });
      this.getIssuesOverTimeFigure();
    },
    openTab(tabId, tabName, method) {
      // Clear the url params, the tabs or methods can populate them
      const url = new URL(window.location.href);
      url.search = "";
      history.replaceState(null, null, url);
      if (method !== "method") {
        // Track tab clicked for mix panel
        mixpanel.track("new_org_dashboard_tab_click", {
          tab_name: tabName,
        });
      }
      // Set the active tab to the clicked tab
      this.activeTab = tabId;
      this.setUrlByTab(tabId);
      if (tabId === 2) {
        this.goToIssuesTab();
      }
      if (tabId === 3) {
        this.goToSystemsTab();
      }
      // Using timeout because next tick is not fast enough to see the element
      if (tabName) {
        this.timeout = setTimeout(() => {
          const element = this.$refs[tabName];
          // Scroll to the active tab
          element[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 1000);
      }
    },
    isActiveTab(tabId) {
      return this.activeTab === tabId;
    },
    goToIssuesTab() {
      // When there is only one org page, we can add a conditional for
      // empty array or nonmatching orgId to route
      this.getIssuesGroupedByType({ orgId: this.orgId });
    },
    goToSystemsTab() {
      // Same as above
      this.getIssuesGroupedByComponent();
    },
    // Call this when tab is clicked
    setUrlByTab(tabId) {
      const url = new URL(window.location.href);
      const tab = this.tabs.find((tab) => tab.id === tabId);
      if (tab) {
        url.hash = tab.name.toLowerCase();
        window.history.replaceState(null, null, url);
      }
    },
    // Call this on mounted
    setTabByUrl(hashName) {
      const tabName = hashName.replace("#", "").toUpperCase();
      const tab = this.tabs.find((tab) => tab.name === tabName);
      if (tab && tab.id) {
        this.openTab(tab.id, tabName, "method");
      }
    },
    // Set the color of the status indicator for status columns in tables
    setStatusColor(issues) {
      if (issues === 0) {
        return "green";
      } else if (issues > 0 && issues < 5) {
        return "warning";
      } else {
        return "error";
      }
    },
    sortIssues(mode) {
      let sortProperty = "n_open";
      // Consolidate the sorting into one function to be used by multiple
      // issue groupings
      function sortArrayByCriterion(array, criterion) {
        return array.sort((a, b) => {
          if (a[criterion] > b[criterion]) {
            return -1;
          }
          if (a[criterion] < b[criterion]) {
            return 1;
          }
          return 0;
        });
      }
      // Sort different issue groupings by open issues
      if (mode === "type") {
        return sortArrayByCriterion(this.issuesByType, sortProperty);
      } else if (mode === "site") {
        return sortArrayByCriterion(this.issuesBySite, sortProperty);
      }
    },
    findFirstTurbineModelName(names) {
      if (Array.isArray(names) && names.length > 0) {
        return names[0];
      } else if (typeof names === "string") {
        return names;
      } else {
        return null;
      }
    },
    openDownloadModal() {
      this.isDownloadModalOpen = !this.isDownloadModalOpen;
    },
    isDateDisabled(dateFromPicker, comparisonDate, type) {
      const utcDate = dayjs(dateFromPicker).utc();
      const utcComparisonDate = dayjs(comparisonDate).utc();
      if (type === "startDate") {
        if (
          utcDate.isSame(utcComparisonDate) ||
          utcDate.isAfter(utcComparisonDate)
        ) {
          this.isStartDateValid = false;
          return true;
        } else {
          this.isStartDateValid = true;
          return false;
        }
      } else if (type === "endDate") {
        if (
          utcDate.isSame(utcComparisonDate) ||
          utcDate.isBefore(utcComparisonDate)
        ) {
          this.isEndDateValid = false;
          return true;
        } else {
          this.isEndDateValid = true;
          return false;
        }
      }
    },
    // These methods handle when a date is picked from the calendar panels
    handlePickStartDate(event) {
      if (this.dateRange.startDate !== event) {
        this.dateRange.startDate = event;
      }
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.validateDateRange();
      }
    },
    handlePickEndDate(event) {
      if (this.dateRange.endDate !== event) {
        this.dateRange.endDate = event;
      }
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.validateDateRange();
      }
    },
    blurDate() {
      this.validateDateRange();
    },
    validateDateRange() {
      const startDate = dayjs(this.dateRange.startDate);
      const endDate = dayjs(this.dateRange.endDate);
      if (startDate.isAfter(endDate)) {
        this.isDateRangeValid = false;
        this.modalMsg = "Start date cannot be after end date";
      } else {
        this.isDateRangeValid = true;
      }
    },
    async exportReport() {
      this.validateDateRange();
      if (this.isDateRangeValid) {
        await this.getOrgIssuesReport();
        this.modalMsg = `Success! The ${this.orgName} issues summary report
          has been exported to ${this.userData.email}.`;
        this.showSuccessMsg = true;
      }
    },
    // API calls
    async getIssuesGroupedByComponent() {
      try {
        this.loadingIssuesByComponent = true;
        const params = {
          group_by: "component",
        };

        const res = await axios.get(`/orgs/${this.orgId}/turbine-issues`, {
          params,
        });

        if (res.status === 200) {
          this.issuesByComponent = res.data;
        } else {
          const error = {
            name: "getIssuesGroupedByComponent",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingIssuesByComponent = false;
    },
    async getIssuesOverTimeFigure() {
      try {
        this.loadingIssuesOverTimeData = true;
        const orgId = parseInt(this.$route.params.orgId);

        const res = await axios.get(`/orgs/${orgId}/plots/issues-over-time`);

        if (res.status === 200) {
          this.issuesOverTimeData = res.data;
        } else {
          this.issuesOverTimeData = null;
          const error = {
            name: "getIssuesOverTimeFigure",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.issuesOverTimeData = null;
        const err = {
          name: "getIssuesOverTimeFigure",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingIssuesOverTimeData = false;
    },
    // Call this when export report button is clicked
    async getOrgIssuesReport() {
      try {
        this.loadingOrgIssuesReport = true;
        const orgId = parseInt(this.$route.params.orgId);
        const params = {
          org_id: orgId,
          start_date: dayjs(this.dateRange.startDate).format("YYYY-MM-DD"),
          end_date: dayjs(this.dateRange.endDate).format("YYYY-MM-DD"),
        };

        const res = await axios.get(`/orgs/${orgId}/issues-summary-report`, {
          params,
        });

        if (res.status === 200) {
          this.getOrgReportResponse = res.data;
        } else {
          this.getOrgReportResponse = null;
          const error = {
            name: "getOrgIssuesReport",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.getOrgReportResponse = null;
        const err = {
          name: "getOrgIssuesReport",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingOrgIssuesReport = false;
    },
  },
  beforeMount() {
    this.callInitialAPIs();
  },
  mounted() {
    const url = new URL(window.location.href);
    if (url.hash) {
      this.setTabByUrl(url.hash);
    }
  },
  watch: {
    isDownloadModalOpen: {
      handler(value) {
        if (!value) {
          this.showSuccessMsg = false;
          this.modalMsg = "";
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.export-dashboard-container {
  position: relative;
}

.grid1 {
  display: grid;
  grid-template-columns: 0.65fr 0.65fr 1fr;
  grid-template-rows: 60px 230px auto auto;
  gap: 0.5rem;
}
.grid1-row-1 {
  grid-column: 1 / 4;
}
.grid1-row-2-col-1 {
  grid-column: 1 / 2;
  border-right: 1px solid var(--v-border-base);
  padding-right: 0.5rem;
  padding-top: 0.45rem;
}
.grid1-row-2-col-2 {
  grid-column: 2 / 3;
  border-right: 1px solid var(--v-border-base);
  padding-right: 0.5rem;
  padding-top: 0.45rem;
}
.grid1-row-2-col-3 {
  grid-column: 3 / 4;
}
.grid1-row-3 {
  grid-column: 1 / 4;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
}
.grid1-row-4 {
  grid-column: 1 / 4;
}

.org-dashboard {
  &__subtitle {
    p {
      font:
        0.75rem "Museo Sans Rounded",
        sans-serif;
      color: var(--v-text-base);
      font-weight: 600;
    }
  }

  &__header {
    position: relative;
    &__help {
      color: var(--v-black3-base);
      box-sizing: border-box;
      padding: 2px 16px 8px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
      .v-icon:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }
}
.main-title-spinner {
  min-width: 7rem;
}

.download-modal {
  position: absolute;
  top: 50;
  left: 5%;
  z-index: 300;
  background: var(--v-background-base);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
::v-deep {
  .date-picker-container {
    .mx-datepicker {
      width: 100%;
    }
  }
  .error-highlight {
    .mx-input {
      border: 2px solid var(--v-error-base);
    }
  }
  .export-btn {
    span.v-btn__content {
      color: var(--v-white-base);
      font:
        0.75rem "Inter",
        sans-serif;
      font-weight: 600;
    }
  }
}
.date-error {
  text-align: center;
  color: var(--v-error-base);
}
.success-msg {
  text-align: center;
  color: var(--v-green-base);
  max-width: 250px;
}
.export-report-subtitle {
  width: 250px;
  font:
    0.875rem "Inter",
    sans-serif;
  color: var(--v-text-base);
}
.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 0.5rem;
  color: var(--v-text-base);
}
.close-icon .v-icon:hover {
  color: var(--v-primary-base);
}
.export-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  z-index: 100;
}

.site-condition-cards {
  height: 100%;
  .availability-card {
    height: 100%;
    min-width: 200px;
  }
  .performance-card {
    height: 100%;
    min-width: 200px;
  }
}

.plot-figure-container {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}
.figure-container {
  height: 300px;
  width: 100%;
}

.content-container {
  position: relative;
  height: 110px;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.content-body,
.footer-body {
  font:
    1.75rem Inter,
    sans-serif;
  font-weight: 500;
}
.content-body {
  text-transform: capitalize;
}

.gain {
  color: $green;
}
.loss {
  color: $red;
}

.org-dashboard-tabbed__section {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  border-top-left-radius: 0;
  min-height: 160px;
}

.map-container {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 1264px) {
  .grid1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px 245px auto auto auto;
    gap: 0.5rem;
  }
  .grid1-row-1 {
    grid-column: 1 / 3;
  }
  .grid1-row-2-col-1 {
    grid-column: 1 / 2;
    border-right: none;
    padding-right: 0;
    margin-left: 0.75rem;
  }
  .grid1-row-2-col-2 {
    grid-column: 2 / 3;
    border-right: none;
    border-left: 1px solid var(--v-border-base);
    padding-left: 0.5rem;
  }
  .grid1-row-2-col-3 {
    grid-column: 1 / 3;
  }
  .grid1-row-3 {
    grid-column: 1 / 3;
    border: 1px solid var(--v-border-base);
    border-radius: 8px;
    margin-top: 0.25rem;
  }
  .grid1-row-4 {
    grid-column: 1 / 3;
  }
}
</style>
