<template>
  <div class="systems-tab-container">
    <TableHeaderFilter
      :headers="headers"
      :selectedHeaders="selectedHeaders"
      menuActivator="#org-dash-systems-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="systems"
      :hasFooter="true"
      :loading="loading"
      tabSelected="systems"
      menuActivator="org-dash-systems-col-filter"
    >
      <template v-slot:header_status="{ header }">
        <div class="d-flex">
          <div v-for="(value, key) in header.text" :key="key">
            <v-icon
              v-if="key === 'icon'"
              size="medium"
              class="ml-1 help-icon-color"
              >{{ value }}</v-icon
            >
            <div v-else>{{ value }}</div>
          </div>
        </div>
      </template>
      <template v-slot:name="{ item }">
        <div class="d-flex justify-space-between flex-wrap">
          <div>{{ transformName(item.name, "display") }}</div>
        </div>
      </template>
      <template v-slot:status="{ item }">
        <div class="status-item">
          <v-icon :color="item.status">mdi-circle-medium</v-icon>
        </div>
      </template>
      <template v-slot:impact_mwh="{ item }">
        <div>
          <span class="mr-1">{{ item.impact_mwh }} MWh</span><span>/</span
          ><span class="ml-1">$</span>{{ item.impact_usd }}
        </div>
      </template>
      <template></template>
    </DynamicTable>
  </div>
</template>

<script>
import { informationIcon } from "@/helpers/variables";
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import debounce from "lodash/debounce";

export default {
  name: "OrgDashboardSystemsTab",
  components: {
    TableHeaderFilter,
    DynamicTable,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    systems: {
      type: Array,
      required: false,
      default: () => [],
    },
    orgId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      informationIcon,
      showAllIssuesInProgress: false,
      headers: [
        {
          id: 1,
          text: "NAME",
          value: "name",
        },
        {
          id: 2,
          text: {
            status: "STATUS",
            icon: informationIcon,
          },
          value: "status",
        },
        {
          id: 3,
          text: "OPEN ISSUES",
          value: "issuesOpen",
        },
        {
          id: 4,
          text: "IMPACT (MWh/$)",
          value: "impact_mwh",
        },
        {
          id: 5,
          text: "ISSUES CLOSED",
          value: "issuesClosed",
        },
        {
          id: 6,
          text: "LAST UPDATED",
          value: "lastUpdated",
        },
      ],
      selectedColumns: [],
      maxColumns: 6,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
    totalItems() {
      return this.systemsData?.length;
    },
    paginatedItems() {
      let startIndex = (this.currentPage - 1) * this.rowsPerPage;
      let endIndex = startIndex + this.rowsPerPage;
      // Using issues array use similar logic as below
      if (this.systemsData?.length > 0) {
        return this.systemsData.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },
    paginationRange() {
      let start = Math.max(this.currentPage - 2, 1);
      let end = Math.min(start + 4, this.totalPages);

      if (this.totalPages - this.currentPage < 2) {
        start = Math.max(this.totalPages - 4, 1);
        end = this.totalPages;
      }

      let range = [];
      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      return range;
    },
    isLastPage() {
      if (!this.rowsPerPage || this.systemsData?.length == 0) {
        return false;
      } else if (
        Math.ceil(this.systemsData?.length / this.rowsPerPage) ===
        this.currentPage
      ) {
        return true;
      }
      return false;
    },
    systemsData() {
      if (this.systems.length > 0) {
        return this.systems;
      }
      return null;
    },
    selectedHeaders() {
      let result = this.headers;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber && pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },
    validatePageInput(event) {
      const pageValue = parseInt(event.target.value);
      if (pageValue && Number.isInteger(pageValue)) {
        this.goToPage(pageValue);
      }
    },
    showAllModal(item) {
      this.selectedItem = item;
      this.showAllIssuesInProgress = !this.showAllIssuesInProgress;
    },
    transformName(name, action) {
      let transformedName = name;
      if (action === "display") {
        if (name.includes("_")) {
          transformedName = name.replace(/_/g, " ");
        }
        if (name.includes("-")) {
          transformedName = name.replace(/-/g, " ");
        }
        return (
          transformedName.charAt(0).toUpperCase() + transformedName.slice(1)
        );
      } else if (action === "route") {
        if (name.includes(" ")) {
          transformedName = name.replace(/ /g, "-");
        }
        if (name.includes("_")) {
          transformedName = name.replace(/_/g, "-");
        }
        return transformedName;
      }
    },
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (
          this.selectedColumns.length === this.headers.length ||
          this.selectedColumns.length === this.maxColumns
        ) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.headers;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.headers;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        const index = this.selectedColumns.indexOf(header);
        if (index !== -1) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          if (this.selectedColumns.length >= this.maxColumns) {
            this.selectedColumns.shift();
          }
          this.selectedColumns.push(header);
        }
      }
      this.ensureMaxColumns();
    },
    handleResize() {
      this.ensureMaxColumns();
    },
    ensureMaxColumns() {
      const width = window.innerWidth;
      this.maxColumns = width < 900 ? 4 : this.headers.length;
      if (this.selectedColumns.length > this.maxColumns) {
        this.selectedColumns = this.selectedColumns.slice(0, this.maxColumns);
      }
    },
  },
  mounted() {
    this.selectedColumns = this.headers;
    this.handleResize = debounce(this.handleResize, 200);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.systems-tab-container {
  position: relative;
}

.open-in-new-icon {
  .v-icon:hover {
    color: var(--v-primary-base) !important;
  }
}

.status-green {
  color: var(--v-stalenessGreen-base);
}
.status-yellow {
  color: var(--v-stalenessYellow-base);
}
.status-red {
  color: var(--v-error-base);
}

.help-icon-color {
  color: var(--v-text-base);
  margin-bottom: 2px;
}
</style>
