import Plotly from "plotly.js-dist-min";

const plotlyFunctions = [
  "restyle",
  "relayout",
  "update",
  "addTraces",
  "deleteTraces",
  "moveTraces",
  "extendTraces",
  "prependTraces",
  "purge",
];

const methods = plotlyFunctions.reduce((all, functionName) => {
  all[functionName] = function (...args) {
    return Plotly[functionName].apply(Plotly, [this.$el, ...args]);
  };
  return all;
}, {});

export default methods;

function cached(fn) {
  const cache = Object.create(null);
  return function cachedFn(str) {
    const hit = cache[str];
    return hit || (cache[str] = fn(str));
  };
}

const regex = /-(\w)/g;
const camelize = cached((str) =>
  str.replace(regex, (_, c) => (c ? c.toUpperCase() : "")),
);

export { camelize };

const eventsName = [
  "AfterExport",
  "AfterPlot",
  "Animated",
  "AnimatingFrame",
  "AnimationInterrupted",
  "AutoSize",
  "BeforeExport",
  "ButtonClicked",
  "Click",
  "ClickAnnotation",
  "Deselect",
  "DoubleClick",
  "Framework",
  "Hover",
  "LegendClick",
  "LegendDoubleClick",
  "Relayout",
  "Restyle",
  "Redraw",
  "Selected",
  "Selecting",
  "SliderChange",
  "SliderEnd",
  "SliderStart",
  "Transitioning",
  "TransitionInterrupted",
  "Unhover",
];

const events = eventsName
  .map((evt) => evt.toLocaleLowerCase())
  .map((eventName) => ({
    completeName: "plotly_" + eventName,
    handler:
      (context) =>
      (...args) => {
        context.$emit.apply(context, [eventName, ...args]);
      },
  }));

export { events };
