<template>
  <div class="sites-tab-container">
    <TableHeaderFilter
      :headers="headers"
      :selectedHeaders="selectedHeaders"
      menuActivator="#org-dash-sites-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="sites"
      :loading="loading"
      :hasFooter="true"
      tabSelected="sites"
      menuActivator="org-dash-sites-col-filter"
    >
      <template v-slot:header_n_open="{ header }">
        <div>
          <div v-for="(value, key) in header.text" :key="key">
            <div>{{ value }}</div>
          </div>
        </div>
      </template>
      <template v-slot:header_turbine_model="{ header }">
        <div>
          <div v-for="(value, key) in header.text" :key="key">
            <div>{{ value }}</div>
          </div>
        </div>
      </template>
      <template v-slot:header_impact_mwh>
        <div>
          <div>IMPACT</div>
          <div class="no-wrap-text">(MWh / $)</div>
        </div>
      </template>
      <template v-slot:header_power_price>
        <div class="no-wrap-text">$/MWh</div>
      </template>
      <template v-slot:site_name="{ item }">
        <router-link
          :to="{
            name: 'NewSiteDashboard',
            params: {
              siteId: item.site_id,
            },
          }"
          class="link-cell"
        >
          <div class="d-flex justify-space-between">
            <div class="link-text">{{ item.site_name }}</div>
            <div class="mr-1 pointer">
              <v-icon size="1.25rem">mdi-open-in-new</v-icon>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:status="{ item }">
        <div class="status-item">
          <v-icon :color="item.status">mdi-circle-medium</v-icon>
        </div>
      </template>
      <template v-slot:impact_mwh="{ item }">
        <div>
          <span class="mr-1">{{ item.impact_mwh }} MWh</span><span>/</span
          ><span class="ml-1">$</span>{{ item.impact_usd }}
        </div>
      </template>
    </DynamicTable>
  </div>
</template>

<script>
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import debounce from "lodash/debounce";

export default {
  name: "OrgDashboardSitesTab",
  components: {
    TableHeaderFilter,
    DynamicTable,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    sites: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          id: 1,
          text: "SITE NAME",
          value: "site_name",
        },
        {
          id: 2,
          text: "STATUS",
          value: "status",
        },
        {
          id: 3,
          text: {
            critical: "CRITICAL",
            issues: "ISSUES",
          },
          value: "n_open",
        },
        {
          id: 4,
          text: {
            impact: "IMPACT",
            noWrapText: "(MWh / $)",
          },
          value: "impact_mwh",
        },
        // {
        //   id: 5,
        //   text: {
        //     data: "DATA",
        //     availability: "AVAILABILITY",
        //   },
        //   value: "data_availability",
        //  sortable: true
        // },
        // {
        //   id: 6,
        //   text: "COD",
        //   value: "cod",
        //   sortable: true
        // },
        {
          id: 7,
          text: {
            turbine: "TURBINE",
            model: "MODEL",
          },
          value: "turbine_model",
        },
        {
          id: 8,
          text: "$/MWh",
          value: "power_price",
        },
        {
          id: 9,
          text: "LAST UPDATED",
          value: "lastUpdated",
          sortable: true,
        },
      ],
      selectedColumns: [],
      maxColumns: 7,
    };
  },
  computed: {
    sitesData() {
      if (this.sites.length > 0) {
        return this.sites;
      }
      return null;
    },
    selectedHeaders() {
      let result = this.headers;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    setStatusColor(status) {
      switch (status) {
        case "critical":
          return "error";
        case "warning":
          return "warning";
        case "optimal":
          return "green";
      }
    },
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (
          this.selectedColumns.length === this.headers.length ||
          this.selectedColumns.length === this.maxColumns
        ) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.headers;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.headers;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        const index = this.selectedColumns.indexOf(header);
        if (index !== -1) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          if (this.selectedColumns.length >= this.maxColumns) {
            this.selectedColumns.shift();
          }
          this.selectedColumns.push(header);
        }
      }
      this.ensureMaxColumns();
    },
    handleResize() {
      this.ensureMaxColumns();
    },
    ensureMaxColumns() {
      const width = window.innerWidth;
      this.maxColumns = width < 900 ? 4 : this.headers.length;
      if (this.selectedColumns.length > this.maxColumns) {
        this.selectedColumns = this.selectedColumns.slice(0, this.maxColumns);
      }
    },
  },
  mounted() {
    this.selectedColumns = this.headers;
    this.handleResize = debounce(this.handleResize, 200);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.sites-tab-container {
  position: relative;
}

.status-green {
  color: var(--v-stalenessGreen-base);
}
.status-yellow {
  color: var(--v-stalenessYellow-base);
}
.status-red {
  color: var(--v-error-base);
}
</style>
