<template>
  <div class="wait-wrapper">
    <div v-if="loading" class="d-flex justify-center ma-6">
      <v-progress-circular
        v-if="!isEmptyLoader"
        :size="spinnerSize"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  name: "WaitWrapper",
  data() {
    return {};
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isEmptyLoader: {
      type: Boolean,
      default: false,
    },
    spinnerSize: {
      type: Number,
      default: 48,
    },
  },
  computed: {},
  methods: {
    closeAlert(toggle) {
      toggle();
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.wait-wrapper {
  position: relative;
}
</style>
